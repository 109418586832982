import React, { Dispatch, useEffect } from 'react';
import firebase from 'firebase';

type State = {
    isAuthenticated: boolean;
    isloading: boolean;
};

const initialState: State = {
    isAuthenticated: false,
    isloading: true
};

type Actions =
    | {
          type: 'SET_AUTENTICATION';
          payload: boolean;
      }
    | { type: 'SET_IS_LOADING'; payload: boolean }
    | { type: 'SIGN_OUT' };

interface IContextProps {
    state: State;
    dispatch: Dispatch<Actions>;
}

const AuthReducer = (state: State, action: Actions): State => {
    switch (action.type) {
        case 'SET_AUTENTICATION':
            return { ...state, isAuthenticated: action.payload };
        case 'SET_IS_LOADING':
            return { ...state, isloading: action.payload };
        case 'SIGN_OUT':
            firebase.auth().signOut();
            return { ...state, isAuthenticated: false };
        default:
            return state;
    }
};

export const AuthContext = React.createContext({} as IContextProps);

export function AuthContextProvider(props: any) {
    const [state, dispatch] = React.useReducer(AuthReducer, initialState);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((authUser) => {
            if (authUser) {
                dispatch({
                    type: 'SET_AUTENTICATION',
                    payload: true
                });
                dispatch({
                    type: 'SET_IS_LOADING',
                    payload: false
                });
            } else {
                dispatch({
                    type: 'SET_AUTENTICATION',
                    payload: false
                });
                dispatch({
                    type: 'SET_IS_LOADING',
                    payload: false
                });
            }
        });
    }, []);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>
    );
}
