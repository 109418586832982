import React from 'react';

import './App.css';

import RouterRoutes from './app_utils/Routes';
import { AuthContextProvider } from './models_context/AuthContext';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { theme } from './app_utils/MuiTheme';
import { AdminContextProvider } from './models_context/AdminContext';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

const App: React.FC = () => {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}>
            <AuthContextProvider>
                <AdminContextProvider>
                    <MuiThemeProvider theme={theme}>
                        <StylesProvider injectFirst>
                            <RouterRoutes></RouterRoutes>
                        </StylesProvider>
                    </MuiThemeProvider>
                </AdminContextProvider>
            </AuthContextProvider>
        </SnackbarProvider>
    );
};

export default App;
