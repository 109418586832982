import * as React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import * as DateFns from 'date-fns';
import { Stand } from '../models/stand';
import ZTimePicker from './ZTimePicker';
import { Grid, Button, Typography, makeStyles, Theme } from '@material-ui/core';
import { HoursOperating } from '../models/hoursOperating';
import { FirestoreService } from '../models_services/FirestoreService';

interface Props {
    stand: Stand;
    openDialogCallback: any;
}

const ZFormTimePicker: React.FC<Props> = ({ stand, openDialogCallback: closeDialog }) => {
    const [hoursOperating, setHoursOperating] = React.useState(stand.hoursOperating);
    const classes = useStyles();

    const onSubmit = () => {
        console.log('hours operating*****************', HoursOperating.toJson(hoursOperating));
        console.log('hours *****************', HoursOperating.toJsonHours(hoursOperating));
        if (stand?.documentId) {
            FirestoreService.updateStand(stand?.documentId, {
                hoursOperating: HoursOperating.toJson(hoursOperating),
                hours: HoursOperating.toJsonHours(hoursOperating),
            });
        }
        closeDialog();
    };

    return (
        <div>
            <div>
                <Typography className={classes.title}>Monday</Typography>
                <Grid container alignItems='center' justify='space-between'>
                    {hoursOperating.monday.isTimeOpenClosed ||
                    hoursOperating.monday.isTimeCloseClosed ? (
                        <>
                            <Grid container item xs={8}>
                                <Typography className={classes.closedShop}>
                                    BaitShop is Closed
                                </Typography>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        monday: {
                                            ...hoursOperating.monday,
                                            isTimeCloseClosed: false,
                                            isTimeOpenClosed: false,
                                        },
                                    });
                                }}>
                                Mark Open
                            </Button>
                        </>
                    ) : (
                        <>
                            <Grid container item xs={8}>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            monday: { ...hoursOperating.monday, timeOpen: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Open'
                                    id='23131'
                                    value={hoursOperating.monday.timeOpen}></ZTimePicker>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            monday: { ...hoursOperating.monday, timeClose: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Close'
                                    id='23131'
                                    value={hoursOperating.monday.timeClose}></ZTimePicker>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='secondary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        monday: {
                                            ...hoursOperating.monday,
                                            isTimeCloseClosed: true,
                                            isTimeOpenClosed: true,
                                        },
                                    });
                                }}>
                                Mark Closed
                            </Button>
                        </>
                    )}
                </Grid>
            </div>

            <div>
                <Typography className={classes.title}>Tuesday</Typography>
                <Grid container alignItems='center' justify='space-between'>
                    {hoursOperating.tuesday.isTimeOpenClosed ||
                    hoursOperating.tuesday.isTimeCloseClosed ? (
                        <>
                            <Grid container item xs={8}>
                                <Typography className={classes.closedShop}>
                                    BaitShop is Closed
                                </Typography>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        tuesday: {
                                            ...hoursOperating.tuesday,
                                            isTimeCloseClosed: false,
                                            isTimeOpenClosed: false,
                                        },
                                    });
                                }}>
                                Mark Open
                            </Button>
                        </>
                    ) : (
                        <>
                            <Grid container item xs={8}>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            tuesday: { ...hoursOperating.tuesday, timeOpen: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Open'
                                    id='23131'
                                    value={hoursOperating.tuesday.timeOpen}></ZTimePicker>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            tuesday: { ...hoursOperating.tuesday, timeClose: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Close'
                                    id='23131'
                                    value={hoursOperating.tuesday.timeClose}></ZTimePicker>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='secondary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        tuesday: {
                                            ...hoursOperating.tuesday,
                                            isTimeCloseClosed: true,
                                            isTimeOpenClosed: true,
                                        },
                                    });
                                }}>
                                Mark Closed
                            </Button>
                        </>
                    )}
                </Grid>
            </div>

            <div>
                <Typography className={classes.title}>Wednesday</Typography>
                <Grid container alignItems='center' justify='space-between'>
                    {hoursOperating.wednesday.isTimeOpenClosed ||
                    hoursOperating.wednesday.isTimeCloseClosed ? (
                        <>
                            <Grid container item xs={8}>
                                <Typography className={classes.closedShop}>
                                    BaitShop is Closed
                                </Typography>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        wednesday: {
                                            ...hoursOperating.wednesday,
                                            isTimeCloseClosed: false,
                                            isTimeOpenClosed: false,
                                        },
                                    });
                                }}>
                                Mark Open
                            </Button>
                        </>
                    ) : (
                        <>
                            <Grid container item xs={8}>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            wednesday: {
                                                ...hoursOperating.wednesday,
                                                timeOpen: val,
                                            },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Open'
                                    id='23131'
                                    value={hoursOperating.wednesday.timeOpen}></ZTimePicker>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            wednesday: {
                                                ...hoursOperating.wednesday,
                                                timeClose: val,
                                            },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Close'
                                    id='23131'
                                    value={hoursOperating.wednesday.timeClose}></ZTimePicker>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='secondary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        wednesday: {
                                            ...hoursOperating.wednesday,
                                            isTimeCloseClosed: true,
                                            isTimeOpenClosed: true,
                                        },
                                    });
                                }}>
                                Mark Closed
                            </Button>
                        </>
                    )}
                </Grid>
            </div>

            <div>
                <Typography className={classes.title}>Thursday</Typography>
                <Grid container alignItems='center' justify='space-between'>
                    {hoursOperating.thursday.isTimeOpenClosed ||
                    hoursOperating.thursday.isTimeCloseClosed ? (
                        <>
                            <Grid container item xs={8}>
                                <Typography className={classes.closedShop}>
                                    BaitShop is Closed
                                </Typography>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        thursday: {
                                            ...hoursOperating.thursday,
                                            isTimeCloseClosed: false,
                                            isTimeOpenClosed: false,
                                        },
                                    });
                                }}>
                                Mark Open
                            </Button>
                        </>
                    ) : (
                        <>
                            <Grid container item xs={8}>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            thursday: { ...hoursOperating.thursday, timeOpen: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Open'
                                    id='23131'
                                    value={hoursOperating.thursday.timeOpen}></ZTimePicker>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            thursday: {
                                                ...hoursOperating.thursday,
                                                timeClose: val,
                                            },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Close'
                                    id='23131'
                                    value={hoursOperating.thursday.timeClose}></ZTimePicker>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='secondary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        thursday: {
                                            ...hoursOperating.thursday,
                                            isTimeCloseClosed: true,
                                            isTimeOpenClosed: true,
                                        },
                                    });
                                }}>
                                Mark Closed
                            </Button>
                        </>
                    )}
                </Grid>
            </div>

            <div>
                <Typography className={classes.title}>Friday</Typography>
                <Grid container alignItems='center' justify='space-between'>
                    {hoursOperating.friday.isTimeOpenClosed ||
                    hoursOperating.friday.isTimeCloseClosed ? (
                        <>
                            <Grid container item xs={8}>
                                <Typography className={classes.closedShop}>
                                    BaitShop is Closed
                                </Typography>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        friday: {
                                            ...hoursOperating.friday,
                                            isTimeCloseClosed: false,
                                            isTimeOpenClosed: false,
                                        },
                                    });
                                }}>
                                Mark Open
                            </Button>
                        </>
                    ) : (
                        <>
                            <Grid container item xs={8}>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            friday: { ...hoursOperating.friday, timeOpen: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Open'
                                    id='23131'
                                    value={hoursOperating.friday.timeOpen}></ZTimePicker>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            friday: { ...hoursOperating.friday, timeClose: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Close'
                                    id='23131'
                                    value={hoursOperating.friday.timeClose}></ZTimePicker>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='secondary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        friday: {
                                            ...hoursOperating.friday,
                                            isTimeCloseClosed: true,
                                            isTimeOpenClosed: true,
                                        },
                                    });
                                }}>
                                Mark Closed
                            </Button>
                        </>
                    )}
                </Grid>
            </div>

            <div>
                <Typography className={classes.title}>Saturday</Typography>
                <Grid container alignItems='center' justify='space-between'>
                    {hoursOperating.saturday.isTimeOpenClosed ||
                    hoursOperating.saturday.isTimeCloseClosed ? (
                        <>
                            <Grid container item xs={8}>
                                <Typography className={classes.closedShop}>
                                    BaitShop is Closed
                                </Typography>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        saturday: {
                                            ...hoursOperating.saturday,
                                            isTimeCloseClosed: false,
                                            isTimeOpenClosed: false,
                                        },
                                    });
                                }}>
                                Mark Open
                            </Button>
                        </>
                    ) : (
                        <>
                            <Grid container item xs={8}>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            saturday: { ...hoursOperating.saturday, timeOpen: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Open'
                                    id='23131'
                                    value={hoursOperating.saturday.timeOpen}></ZTimePicker>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            saturday: {
                                                ...hoursOperating.saturday,
                                                timeClose: val,
                                            },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Close'
                                    id='23131'
                                    value={hoursOperating.saturday.timeClose}></ZTimePicker>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='secondary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        saturday: {
                                            ...hoursOperating.saturday,
                                            isTimeCloseClosed: true,
                                            isTimeOpenClosed: true,
                                        },
                                    });
                                }}>
                                Mark Closed
                            </Button>
                        </>
                    )}
                </Grid>
            </div>

            <div>
                <Typography className={classes.title}>Sunday</Typography>
                <Grid container alignItems='center' justify='space-between'>
                    {hoursOperating.sunday.isTimeOpenClosed ||
                    hoursOperating.sunday.isTimeCloseClosed ? (
                        <>
                            <Grid container item xs={8}>
                                <Typography className={classes.closedShop}>
                                    BaitShop is Closed
                                </Typography>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        sunday: {
                                            ...hoursOperating.sunday,
                                            isTimeCloseClosed: false,
                                            isTimeOpenClosed: false,
                                        },
                                    });
                                }}>
                                Mark Open
                            </Button>
                        </>
                    ) : (
                        <>
                            <Grid container item xs={8}>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            sunday: { ...hoursOperating.sunday, timeOpen: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Open'
                                    id='23131'
                                    value={hoursOperating.sunday.timeOpen}></ZTimePicker>
                                <ZTimePicker
                                    className={classes.timePicker}
                                    onChange={(val: Date) => {
                                        setHoursOperating({
                                            ...hoursOperating,
                                            sunday: { ...hoursOperating.sunday, timeClose: val },
                                        });

                                        console.log(hoursOperating);
                                    }}
                                    label='Close'
                                    id='23131'
                                    value={hoursOperating.sunday.timeClose}></ZTimePicker>
                            </Grid>
                            <Button
                                size='small'
                                variant='outlined'
                                color='secondary'
                                onClick={() => {
                                    setHoursOperating({
                                        ...hoursOperating,
                                        sunday: {
                                            ...hoursOperating.sunday,
                                            isTimeCloseClosed: true,
                                            isTimeOpenClosed: true,
                                        },
                                    });
                                }}>
                                Mark Closed
                            </Button>
                        </>
                    )}
                </Grid>
            </div>

            <Grid container justify='flex-end' className={classes.buttonsCancelSubmit}>
                <Button
                    color='secondary'
                    onClick={() => {
                        closeDialog(false);
                    }}>
                    Cancel
                </Button>
                <Button
                    color='primary'
                    onClick={() => {
                        onSubmit();
                    }}>
                    Save
                </Button>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '18px',
        color: '#1b3a57',
        fontWeight: 700,
        marginTop: '15px',
    },

    closedShop: {},
    timePicker: {},

    buttonsCancelSubmit: {
        marginTop: '30px',
    },
}));

ZFormTimePicker.defaultProps = {};

export default ZFormTimePicker;
