export class Stock implements IStock {
    stockName: string;
    documentId: string;
    stockId: string;
    standId: string;
    salt: boolean;
    keepsLive: boolean;
    keepsFresh: boolean;
    keepsFrozen: boolean;
    live: KeepsDetails;
    fresh: KeepsDetails;
    frozen: KeepsDetails;

    constructor(s: IStock) {
        this.documentId = s.documentId;
        this.keepsFresh = s.keepsFresh;
        this.keepsFrozen = s.keepsFrozen;
        this.keepsLive = s.keepsLive;
        this.salt = s.salt;
        this.standId = s.standId;
        this.stockId = s.stockId;
        this.stockName = s.stockName;
        this.live = s.live;
        this.fresh = s.fresh;
        this.frozen = s.frozen;
    }

    static fromFirestore(
        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ): Stock | null {
        const data = doc.data();

        if (!data) return null;

        return new Stock({
            documentId: doc.id,
            fresh: KeepsDetails.fromMap(data['fresh']),
            frozen: KeepsDetails.fromMap(data['frozen']),
            keepsFresh: data['keepsFresh'] ?? false,
            keepsFrozen: data['keepsFrozen'] ?? false,
            keepsLive: data['keepsLive'] ?? false,
            live: KeepsDetails.fromMap(data['live']),
            salt: data['salt'] ?? false,
            standId: data['standId'] ?? '',
            stockId: data['stockId'] ?? '',
            stockName: data['stockName'] ?? '',
        });
    }
}

export class KeepsDetails implements IKeepsDetails {
    available: boolean;
    enableReservation: boolean;
    pricing: Pricing[];

    constructor(v: IKeepsDetails) {
        this.available = v.available;
        this.enableReservation = v.enableReservation;
        this.pricing = v.pricing;
    }

    static fromMap(map: Record<string, any>): KeepsDetails {
        if (!map)
            return new KeepsDetails({ available: false, enableReservation: false, pricing: [] });

        return new KeepsDetails({
            available: map['available'] ?? false,
            enableReservation: map['enableReservation'] ?? false,
            pricing: Pricing.fromListMap(map['pricing']),
        });
    }
}

export class Pricing implements IPricing {
    price: number;
    unit: string;
    currency: string;

    constructor(s: IPricing) {
        this.currency = s.currency;
        this.price = s.price;
        this.unit = s.unit;
    }

    static fromMap(map: Record<string, any>): Pricing {
        return new Pricing({
            price: map['price'] ?? 0,
            currency: map['currency'] ?? '',
            unit: map['unit'] ?? '',
        });
    }
    static fromListMap(map: Record<string, any>[]): Pricing[] {
        let pricings: Pricing[] = [];

        if (map) {
            map.forEach((x) => {
                pricings.push({
                    currency: x['currency'] ?? '',
                    price: x['price'] ?? 0,
                    unit: x['unit'] ?? '',
                });
            });
        }

        return pricings;
    }

    static toMap(v: IPricing): Record<string, any> {
        return {
            price: v.price,
            currency: v.currency,
            unit: v.unit,
        };
    }
}

interface IStock {
    stockName: string;
    documentId: string;
    stockId: string;
    standId: string;
    salt: boolean;
    keepsLive: boolean;
    keepsFresh: boolean;
    keepsFrozen: boolean;
    live: KeepsDetails;
    fresh: KeepsDetails;
    frozen: KeepsDetails;
}

interface IKeepsDetails {
    available: boolean;
    enableReservation: boolean;
    pricing: Pricing[];
}

interface IPricing {
    price: number;
    unit: string;
    currency: string;
}
