import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1440,
        },
    },
});
