import * as React from 'react';
import { Drawer, Grid, Card, Button, Divider } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import ZText from './ZText';
import ZSwitch from './ZSwitch';
import { FirestoreService } from '../models_services/FirestoreService';
import { AdminContext } from '../models_context/AdminContext';
import ZChip from './ZChip';
import { Bait } from '../models/bait';

interface Props {
    open: boolean;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    stock?: any;
    standId: string | null;
}

const ZDrawerBait: React.FC<Props> = ({ open, setOpenDrawer, stock, standId }) => {
    const { state, dispatch } = React.useContext(AdminContext);
    const [isLoading, setIsloading] = React.useState(true);
    const [showForm, setShowForm] = React.useState(false);
    const [baitType, setBaitType] = React.useState('');

    const [bait, setBait] = React.useState({
        freshAvailable: false,
        freshEnableReservation: false,
        frozenAvailable: false,
        frozenEnableReservation: false,
        keepsFresh: false,
        keepsFrozen: false,
        keepsLive: false,
        liveAvailable: false,
        liveEnableReservation: false,
        salt: false,
        standId: standId,
        stockId: '',
        stockName: '',
    });

    const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setBait({ ...bait, [event.target.name]: event.target.checked });
    };

    const postBait = () => {
        const data = {
            fresh: {
                available: bait.freshAvailable,
                enableReservation: bait.freshEnableReservation,
            },
            frozen: {
                available: bait.frozenAvailable,
                enableReservation: bait.frozenEnableReservation,
            },
            live: {
                available: bait.liveAvailable,
                enableReservation: bait.liveEnableReservation,
            },
            keepsFresh: bait.keepsFresh,
            keepsFrozen: bait.keepsFrozen,
            keepsLive: bait.keepsLive,
            salt: bait.salt,
            standId: standId,
            stockId: bait.stockId,
            stockName: bait.stockName,
        };

        if (stock) {
            FirestoreService.updateBait(stock.documentId, { ...data });
        } else {
            FirestoreService.addBait({ ...data });
        }
        setOpenDrawer(false);
    };

    const updateBaitFromProduct = (product: any) => {
        setBait({
            ...bait,
            freshAvailable: false,
            freshEnableReservation: false,
            frozenAvailable: false,
            frozenEnableReservation: false,
            keepsFresh: false,
            keepsFrozen: false,
            keepsLive: false,
            liveAvailable: false,
            liveEnableReservation: false,
            salt: product.salt ?? false,
            stockName: product.name,
            stockId: product.documentId,
        });
        setShowForm(true);
    };

    React.useEffect(() => {
        setShowForm(false);
        setBait({ ...bait, standId: standId });
        setBaitType('All');

        if (open && !stock) {
            const getProducts = async () => {
                const baits = await FirestoreService.getBaits();
                dispatch({ type: 'SET_BAITS', payload: baits });
                setIsloading(false);
            };
            getProducts();
        }

        if (stock) {
            setBait({
                ...bait,
                freshAvailable: stock?.fresh?.available ?? false,
                freshEnableReservation: stock?.fresh?.enableReservation ?? false,
                frozenAvailable: stock?.frozen?.available ?? false,
                frozenEnableReservation: stock?.frozen?.enableReservation ?? false,
                keepsFresh: stock?.keepsFresh ?? false,
                keepsFrozen: stock?.keepsFrozen ?? false,
                keepsLive: stock?.keepsLive ?? false,
                liveAvailable: stock?.live?.available ?? false,
                liveEnableReservation: stock?.live?.enableReservation ?? false,
                salt: stock?.salt ?? false,
                stockId: stock?.stockId ?? '',
                stockName: stock?.stockName ?? '',
            });
            setShowForm(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, open, stock]);

    return (
        <Drawer anchor='right' open={open} onClose={() => setOpenDrawer(false)} css={cssStyles}>
            <div className='drawer'>
                {!stock && !showForm && (
                    <div>
                        {state.baits.map((bait, index) => (
                            <div>
                                {index === 0 && (
                                    <div>
                                        <div className='text-title'>Please select a bait</div>
                                        <Grid container className='button-grid'>
                                            <Grid item xs={4}>
                                                <Button
                                                    size='small'
                                                    className={`${
                                                        baitType === 'All' ? 'button-selected' : ''
                                                    }`}
                                                    variant='outlined'
                                                    onClick={() => {
                                                        setBaitType('All');
                                                    }}>
                                                    All
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    size='small'
                                                    className={`${
                                                        baitType === 'Salt' ? 'button-selected' : ''
                                                    }`}
                                                    variant='outlined'
                                                    onClick={() => {
                                                        setBaitType('Salt');
                                                    }}>
                                                    Salt
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    size='small'
                                                    className={`${
                                                        baitType === 'Fresh'
                                                            ? 'button-selected'
                                                            : ''
                                                    }`}
                                                    variant='outlined'
                                                    onClick={() => {
                                                        setBaitType('Fresh');
                                                    }}>
                                                    Fresh
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        ))}

                        {baitType === 'All' && (
                            <div>
                                {state.baits.map((bait, index) => (
                                    <div key={bait.documentId}>
                                        <ZChip
                                            className={`${bait.salt ? 'bait-salt' : 'bait-fresh'}`}
                                            name={bait.documentId}
                                            onClick={() => {
                                                updateBaitFromProduct(bait);
                                            }}></ZChip>
                                    </div>
                                ))}
                            </div>
                        )}

                        {baitType === 'Salt' && (
                            <div>
                                {state.baitsSalt.map((bait, index) => (
                                    <div key={bait.documentId}>
                                        <ZChip
                                            className={`${bait.salt ? 'bait-salt' : 'bait-fresh'}`}
                                            name={bait.documentId}
                                            onClick={() => {
                                                updateBaitFromProduct(bait);
                                            }}></ZChip>
                                    </div>
                                ))}
                            </div>
                        )}

                        {baitType === 'Fresh' && (
                            <div>
                                {state.baitsFresh.map((bait, index) => (
                                    <div key={bait.documentId}>
                                        <ZChip
                                            className={`${bait.salt ? 'bait-salt' : 'bait-fresh'}`}
                                            name={bait.documentId}
                                            onClick={() => {
                                                updateBaitFromProduct(bait);
                                            }}></ZChip>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {showForm ? (
                    <>
                        {stock && (
                            <div>
                                <Button
                                    onClick={() => {
                                        FirestoreService.deleteBait(stock.documentId);
                                        setOpenDrawer(false);
                                    }}
                                    variant='outlined'
                                    color='secondary'
                                    fullWidth
                                    className='button'>
                                    Delete this bait
                                </Button>
                            </div>
                        )}
                        <div className='text-title'>{bait.stockId}</div>
                        <Card className='card'>
                            <div className='text'>Live</div>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='keepsLive'
                                label='Do you keep this type of bait?'
                                value={bait.keepsLive}></ZSwitch>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='liveAvailable'
                                label='Do you currently have this available?'
                                value={bait.liveAvailable}></ZSwitch>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='liveEnableReservation'
                                label='Are you currently accepting reservation'
                                value={bait.liveEnableReservation}></ZSwitch>
                        </Card>
                        <Card className='card'>
                            <div className='text'>Fresh</div>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='keepsFresh'
                                label='Do you keep this type of bait?'
                                value={bait.keepsFresh}></ZSwitch>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='freshAvailable'
                                label='Do you currently have this available?'
                                value={bait.freshAvailable}></ZSwitch>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='freshEnableReservation'
                                label='Are you currently accepting reservation'
                                value={bait.freshEnableReservation}></ZSwitch>
                        </Card>
                        <Card className='card'>
                            <div className='text'>Frozen</div>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='keepsFrozen'
                                label='Do you keep this type of bait?'
                                value={bait.keepsFrozen}></ZSwitch>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='frozenAvailable'
                                label='Do you currently have this available?'
                                value={bait.frozenAvailable}></ZSwitch>
                            <ZSwitch
                                onChange={handleOnChange}
                                name='frozenEnableReservation'
                                label='Are you currently accepting reservation'
                                value={bait.frozenEnableReservation}></ZSwitch>
                        </Card>
                        <Button
                            onClick={postBait}
                            variant='outlined'
                            color='primary'
                            fullWidth
                            className='button'>
                            Save
                        </Button>
                    </>
                ) : null}
            </div>
        </Drawer>
    );
};

const cssStyles = css`
    .button {
        margin: 5px;
    }
    .text-title {
        margin: 10px 0px;
        font-weight: 800;
        font-style: italic;
        font-size: 20px;
    }
    .button-grid {
        margin: 10px 0px;

        .button-selected {
            background-color: #0555a2;
            color: white;
        }
    }

    .bait-salt {
        color: #fff;
        background-color: #4dae4d;
    }

    .bait-fresh {
        color: #fff;
        background-color: #0555a2;
    }
    .card {
        margin: 20px 5px;
        padding: 10px;
    }
    .drawer {
        width: 380px;
        height: 100%;
        padding: 10px;
        overflow: auto;
    }
    .material-icons {
        color: white;
        font-size: 22px;
    }
    .MuiTypography-body1 {
        font-size: 0.95rem;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
    }
`;

ZDrawerBait.defaultProps = {};

export default ZDrawerBait;
