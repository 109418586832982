import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Grid, Button, Divider, Card } from '@material-ui/core';
import ZBaitChip from './ZBaitChip';
import { FirestoreService } from '../models_services/FirestoreService';
import { Stock } from '../models/stock';

interface Props {
    stock: Stock;
    onClickBait: () => void;
    onClickPrice: () => void;
}

const ZBaitCard: React.FC<Props> = ({ stock, onClickBait, onClickPrice }) => {
    return (
        <div css={cssStyles}>
            <Grid item className=''>
                <Card className={`card ${stock.salt ? 'stock-salt' : 'stock-fresh'}`}>
                    <Grid container justify='space-between' alignItems='center'>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <Button
                                    className='edit-button-bait'
                                    variant='text'
                                    onClick={onClickBait}>
                                    Edit Bait
                                </Button>

                                <Button
                                    className='edit-button-price'
                                    variant='text'
                                    onClick={onClickPrice}>
                                    Edit Price
                                </Button>
                            </Grid>
                        </Grid>

                        <Divider />

                        <Grid item>
                            <div className='card-title'>{stock.stockName}</div>
                        </Grid>
                    </Grid>

                    <Grid container justify='space-around'>
                        <Grid item xs={3}>
                            <ZBaitChip
                                keeps={stock.keepsLive}
                                label='Live'
                                available={stock.live.available}
                                onClick={() => {
                                    FirestoreService.updateBait(stock.documentId, {
                                        live: {
                                            available: !stock.live.available,
                                        },
                                        keepsLive: true,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ZBaitChip
                                keeps={stock.keepsFresh}
                                label='Fresh'
                                available={stock.fresh.available}
                                onClick={() => {
                                    FirestoreService.updateBait(stock.documentId, {
                                        fresh: {
                                            available: !stock.fresh.available,
                                        },
                                        keepsFresh: true,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ZBaitChip
                                keeps={stock.keepsFrozen}
                                label='Frozen'
                                available={stock.frozen.available}
                                onClick={() => {
                                    FirestoreService.updateBait(stock.documentId, {
                                        frozen: {
                                            available: !stock.frozen.available,
                                        },
                                        keepsFrozen: true,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </div>
    );
};

ZBaitCard.defaultProps = {};

const cssStyles = css`
    .bait-button {
        width: 1440px;
        margin: auto;
        margin-bottom: 10px;
    }

    .stock-salt {
        background-color: #f1f5f7;
    }
    .stock-fresh {
    }

    .button-bar {
        margin-top: 20px;

        .button {
            padding-left: 10px;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
        }
    }

    .divider {
        margin: 20px 0px;
        height: 1px;
        border: solid 1px rgba(112, 112, 112, 0.05);
    }

    .edit-button-price {
        font-size: 11px;
        margin-left: 10px;
        margin-bottom: 5px;
        color: #00b894;
    }

    .edit-button-bait {
        font-size: 11px;
        margin-left: 10px;
        margin-bottom: 5px;
        color: #0984e3;
    }

    .card {
        margin: 15px;
        margin-bottom: 25px;
        padding: 10px;
    }

    .card-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .buttons {
        width: 1200px;
        margin: auto;
        margin-bottom: 10px;
    }

    .btn {
        margin-right: 20px;
    }

    .divider {
        width: 1240px;
        margin: auto;
        margin-bottom: 10px;
    }
`;

export default ZBaitCard;
