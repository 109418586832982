import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Grid, Divider } from '@material-ui/core';
import { Reservation } from '../models/reservation';

interface Props {
    reservation: Reservation;
    onClick: () => void;
}

const ZReservationCard: React.FC<Props> = ({ reservation, onClick }) => {
    return (
        <div css={cssStyles} onClick={onClick}>
            <div className={`${reservation.rejected ? 'rejected' : ''}`}>
                <Grid container justify='space-between' alignItems='center'>
                    <div>
                        <div className='name'>{reservation.username}</div>
                        <div className='name-email'>{reservation.email}</div>
                    </div>
                    <div className='total-price'>${reservation.totalAmount}</div>
                </Grid>

                <Grid container justify='space-between'>
                    <Grid className='items'>
                        {reservation.items.map((x, index: number) => (
                            <div key={index}>
                                {index < 2 ? (
                                    <div>
                                        {x.quantity} {x.pricing.unit} {x.name}
                                    </div>
                                ) : null}
                                {index === 2 ? (
                                    <div>({reservation.items.length}...more)</div>
                                ) : null}
                            </div>
                        ))}
                    </Grid>
                    <Grid className='status'>
                        <div>{reservation.pickUpDateTimeStr}</div>
                        <div>{reservation.timeslot.timeslotString}</div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

ZReservationCard.defaultProps = {};

const cssStyles = css`
    background-color: #f9f9f9;
    border: solid 1px rgba(112, 112, 112, 0.14);
    border-radius: 5px;
    width: 100%;
    margin: 0px 10px;
    margin-bottom: 20px;
    height: 115px;
    :hover {
        cursor: pointer;
    }

    .rejected {
        background-color: red;
        height: 115px;
        border-radius: 5px;
        background-color: rgba(255, 0, 0, 0.2);
    }

    .name {
        margin-left: 10px;
        margin-top: 10px;
        letter-spacing: normal;
        font-size: 14px;
        text-align: left;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.54);
    }
    .name-email {
        margin-left: 10px;
        margin-top: 2px;
        letter-spacing: normal;
        font-size: 13px;
        text-align: left;
        color: rgba(0, 0, 0, 0.54);
    }

    .order-number {
        margin-right: 10px;
        margin-top: 10px;
        letter-spacing: normal;
        font-size: 14px;
        text-align: left;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.54);
    }

    .items {
        margin-left: 10px;
        margin-top: 15px;
        letter-spacing: normal;
        font-size: 14px;
        text-align: left;
        color: rgba(0, 0, 0, 0.68);
    }
    .delivery-time {
        margin-right: 10px;

        margin-top: 10px;
        text-align: right;
        letter-spacing: normal;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.68);
    }

    .divider-card {
        margin: 15px 0px;
        border: solid 0.5px rgba(112, 112, 112, 0.11);
    }

    .total-price {
        margin-right: 10px;
        margin-top: 10px;
        letter-spacing: normal;
        font-size: 18px;
        text-align: left;
        font-style: italic;
        color: #00b894;
        font-weight: 700;
    }
    .status {
        margin-right: 10px;
        margin-top: 15px;
        letter-spacing: normal;
        font-size: 12.5px;
        text-align: right;
        color: #476282;
        font-weight: 700;
    }
`;

export default ZReservationCard;
