import * as React from 'react';
import { Drawer, Grid, Card, Button, Divider, TextField } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { firebase, db } from '../firebase';
import { Report } from '../models/report';
import ZTextField from './ZTextField';
import { useForm } from 'react-hook-form';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';
import { AdminContext } from '../models_context/AdminContext';
import { FirestoreService } from '../models_services/FirestoreService';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as DateFns from 'date-fns';

export enum ResevationProgress {
    accept,
    confirm,
    deliver,
    history,
}

type FormData = {
    title: string;
    description: string;
};

interface Props {
    open: boolean;
    setOpenDrawerReport: React.Dispatch<React.SetStateAction<boolean>>;
    report: Report | null | undefined;
}

const imageResizeFileUri = ({ file }: { file: File }) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            700,
            700,
            'JPEG',
            95,
            0,
            (uri: string) => {
                resolve(uri);
            },
            'base64'
        );
    });

const ZDrawerReport: React.FC<Props> = ({ open, setOpenDrawerReport, report }) => {
    const [selectedDate, setSelectedDate] = React.useState<Date>(DateFns.toDate(Date.now()));
    const [imageFile, setImageFile] = React.useState<File | null>();
    const [imageStr, setImageStr] = React.useState<string | null>();
    const { errors, handleSubmit, control } = useForm<FormData>({});
    const { state, dispatch } = React.useContext(AdminContext);

    React.useEffect(() => {
        if (report) {
            setSelectedDate(DateFns.toDate(report?.timestampMs));
        }
        if (!report) {
            setSelectedDate(DateFns.toDate(Date.now()));
        }
    }, [report]);

    const handleDateChange = (date: Date | null) => {
        if (date) setSelectedDate(date);
    };

    async function updateImage(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            if (event.target.files[0]) {
                setImageFile(event.target.files[0]);
                setImageStr(URL.createObjectURL(event.target.files[0]));
            }
        }
    }

    const onSubmit = async (data: FormData) => {
        let downloadUrl;
        let imageUri;
        if (imageFile) {
            imageUri = (await imageResizeFileUri({ file: imageFile })) as string;

            const storageRef = firebase
                .storage()
                .ref()
                .child('v2reports')
                .child(`${state.stand?.documentId}`)
                .child(`${uuidv4()}.jpeg`);

            if (imageUri) {
                await storageRef.putString(imageUri, 'data_url');
                downloadUrl = await storageRef.getDownloadURL();
            }
        }

        if (!report) {
            const firestoreData = {
                description: data.description,
                photo: downloadUrl,
                standId: state.stand?.documentId,
                standName: state.stand?.name,
                timestamp: DateFns.toDate(selectedDate).toISOString(),
                timestampMs: DateFns.getTime(selectedDate),
                title: data.title,
            };

            await db.collection('v2reports').doc().set(firestoreData);
        }

        if (report) {
            let photo = report.photo;
            if (downloadUrl) photo = downloadUrl;
            const firestoreData = {
                title: data.title,
                description: data.description,
                photo: photo,
                timestamp: DateFns.toDate(selectedDate).toISOString(),
                timestampMs: DateFns.getTime(selectedDate),
            };

            await db
                .collection('v2reports')
                .doc(report.documentId)
                .set(firestoreData, { merge: true });
        }

        setOpenDrawerReport(false);
    };

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={() => {
                setOpenDrawerReport(false);
                setImageFile(null);
                setImageStr(null);
            }}
            css={cssStyles}>
            <div className='drawer'>
                {report && (
                    <div className='delete-btn'>
                        <Button
                            onClick={() => {
                                FirestoreService.deleteReport(report.documentId);
                                setOpenDrawerReport(false);
                            }}
                            variant='outlined'
                            color='secondary'
                            fullWidth>
                            Delete this report
                        </Button>
                    </div>
                )}

                <form key={'form'} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div>
                            <div className='page-title'>
                                {report ? 'Update Report' : 'New Report'}
                            </div>

                            <div className='select-image'>
                                {!report?.photo && !imageStr && <div>Please select image</div>}

                                {report?.photo && !imageStr && (
                                    <div>
                                        <img src={report.photo} alt='' />
                                    </div>
                                )}

                                {imageStr && (
                                    <div>
                                        <img src={imageStr} alt='' />
                                    </div>
                                )}

                                <div className='upload-btn'>
                                    <input
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                        id='raised-button-file'
                                        onChange={updateImage}
                                        type='file'
                                    />
                                    <label htmlFor='raised-button-file'>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            component='span'>
                                            {report?.photo || imageStr ? 'Change' : 'Upload'}
                                        </Button>
                                    </label>
                                </div>
                            </div>

                            <ZTextField
                                name='title'
                                rules={{ required: 'This field is required' }}
                                control={control}
                                error={errors?.title ? true : false}
                                helperText={errors.title?.message}
                                label='Title'
                                defaultValue={report?.title ?? ''}
                                className='text-field'></ZTextField>

                            <ZTextField
                                name='description'
                                rules={{ required: 'This field is required' }}
                                control={control}
                                rows={5}
                                error={errors?.description ? true : false}
                                helperText={errors.description?.message}
                                label='Description'
                                defaultValue={report?.description ?? ''}
                                className='text-field'></ZTextField>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    disableToolbar
                                    variant='inline'
                                    format='MMM/dd/yyyy'
                                    margin='normal'
                                    id='date-picker-inline'
                                    label='Report Date'
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                            <Button
                                type='submit'
                                disabled={!report && !imageStr}
                                fullWidth
                                variant='contained'
                                color='primary'
                                className='submit-btn'>
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Drawer>
    );
};

const cssStyles = css`
    .drawer {
        width: 380px;
        height: 100%;
        padding: 10px;
        overflow: auto;
    }

    .page-title {
        font-size: 20px;
        font-weight: 700;
        margin: 20px 0px;
    }

    .submit-btn {
        margin-top: 20px;
    }

    .select-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 240px;
        width: 100%;
        border: solid 1px rgba(112, 112, 112, 0.5);
        border-radius: 5px;
        margin-bottom: 10px;
        text-align: center;
        position: relative;

        img {
            margin-top: 3px;
            margin-right: 3px;
            height: 240px;
            width: 100%;

            border-radius: 5px;
        }

        .upload-btn {
            position: absolute;
            bottom: 10px;
            right: 10px;
            cursor: pointer;
        }
    }

    .delete-btn {
        padding: 10px 5px;
    }

    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 240px;
        width: 100%;
        border: solid 1px rgba(112, 112, 112, 0.5);
        border-radius: 5px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;

        img {
            height: 240px;
            width: 100%;
        }
    }
    .image-input {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 240px;
        width: 100%;
        border: solid 1px rgba(112, 112, 112, 0.5);
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;

        input {
            background-color: transparent;
            opacity: 0;
            position: absolute;

            bottom: 0;
            right: 0;

            cursor: pointer;
        }
    }
`;

ZDrawerReport.defaultProps = {};

export default ZDrawerReport;
