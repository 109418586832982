import { HoursOperating } from './hoursOperating';

export class Stand implements IStand {
    address: string;
    admins: string[];
    allowServiceReservations: boolean;
    app: string;
    associates: string[];
    currency: string;
    documentId: string;
    enableGuideReservations: boolean;
    enablePayNow: boolean;
    enableUserReservations: boolean;
    guideReservationFee: number;
    guides: string[];
    masterAllowServiceReservations: boolean;
    masterEnablePayNow: boolean;
    masterEnableReservations: boolean;
    name: string;
    passCcChargesToUser: boolean;
    phone: string;
    profileUrl: string;
    reservationFee: number;
    reservationFeeTierOne: number;
    reservationFeeTierThree: number;
    reservationFeeTierTwo: number;
    salesTax: number;
    serviceGuideReservationFee: number;
    serviceReservationFee: number;
    serviceReservationFeeTierOne: number;
    serviceReservationFeeTierThree: number;
    serviceReservationFeeTierTwo: number;
    standNotificationToken: string;
    standStripeKey: string;
    website: string;
    hours: Hours;
    isSelected: boolean;
    allowTouchlessDelivery: boolean;
    enforceTouchlessDelivery: boolean;
    hoursOperating: HoursOperating;

    constructor(x: Stand) {
        this.address = x.address;
        this.admins = x.admins;
        this.allowServiceReservations = x.allowServiceReservations;
        this.app = x.app;
        this.associates = x.associates;
        this.currency = x.currency;
        this.documentId = x.documentId;
        this.enableGuideReservations = x.enableGuideReservations;
        this.enablePayNow = x.enablePayNow;
        this.enableUserReservations = x.enableUserReservations;
        this.guideReservationFee = x.guideReservationFee;
        this.guides = x.guides;
        this.masterAllowServiceReservations = x.masterAllowServiceReservations;
        this.masterEnablePayNow = x.masterEnablePayNow;
        this.masterEnableReservations = x.masterEnableReservations;
        this.name = x.name;
        this.passCcChargesToUser = x.passCcChargesToUser;
        this.phone = x.phone;
        this.profileUrl = x.profileUrl;
        this.reservationFee = x.reservationFee;
        this.reservationFeeTierOne = x.reservationFeeTierOne;
        this.reservationFeeTierThree = x.reservationFeeTierThree;
        this.reservationFeeTierTwo = x.reservationFeeTierTwo;
        this.salesTax = x.salesTax;
        this.serviceGuideReservationFee = x.serviceGuideReservationFee;
        this.serviceReservationFee = x.serviceReservationFee;
        this.serviceReservationFeeTierOne = x.serviceReservationFeeTierOne;
        this.serviceReservationFeeTierThree = x.serviceReservationFeeTierThree;
        this.serviceReservationFeeTierTwo = x.serviceReservationFeeTierTwo;
        this.standNotificationToken = x.standNotificationToken;
        this.standStripeKey = x.standStripeKey;
        this.website = x.website;
        this.hours = x.hours;
        this.isSelected = x.isSelected;
        this.allowTouchlessDelivery = x.allowTouchlessDelivery;
        this.enforceTouchlessDelivery = x.enforceTouchlessDelivery;
        this.hoursOperating = x.hoursOperating;
    }

    static fromFirestore(
        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ): Stand | null {
        const data = doc.data();

        if (!data) return null;

        return new Stand({
            address: data['address'] ?? '',
            admins: data['admins'] ?? '',
            allowServiceReservations: data['allowServiceReservations'] ?? false,
            app: data['app'] ?? '',
            associates: data['associates'] ?? '',
            currency: data['currency'] ?? '',
            documentId: doc.id,
            enableGuideReservations: data['enableGuideReservations'] ?? false,
            enablePayNow: data['enablePayNow'] ?? false,
            enableUserReservations: data['enableUserReservations'] ?? false,
            guideReservationFee: data['guideReservationFee'] ?? 0,
            guides: data['guides'] ?? '',
            masterAllowServiceReservations: data['masterAllowServiceReservations'] ?? false,
            masterEnablePayNow: data['masterEnablePayNows'] ?? 0,
            masterEnableReservations: data['masterEnableReservations'] ?? 0,
            name: data['name'] ?? '',
            passCcChargesToUser: data['passCcChargesToUser'] ?? false,
            phone: data['phone'] ?? '',
            profileUrl: data['profileUrl'] ?? '',
            reservationFee: data['reservationFee'] ?? 0,
            reservationFeeTierOne: data['reservationFeeTierOne'] ?? 0,
            reservationFeeTierThree: data['reservationFeeTierThree'] ?? 0,
            reservationFeeTierTwo: data['reservationFeeTierTwo'] ?? 0,
            salesTax: data['salesTax'] ?? 0,
            serviceGuideReservationFee: data['serviceGuideReservationFee'] ?? false,
            serviceReservationFee: data['serviceReservationFee'] ?? false,
            serviceReservationFeeTierOne: data['serviceReservationFeeTierOne'] ?? false,
            serviceReservationFeeTierThree: data['serviceReservationFeeTierThree'] ?? false,
            serviceReservationFeeTierTwo: data['serviceReservationFeeTierTwo'] ?? false,
            standNotificationToken: data['standNotificationToken'] ?? '',
            standStripeKey: data['standStripeKey'] ?? '',
            website: data['website'] ?? '',
            hours: Hours.fromMap(data['hours']),
            isSelected: false,
            allowTouchlessDelivery: data['allowTouchlessDelivery'] ?? false,
            enforceTouchlessDelivery: data['enforceTouchlessDelivery'] ?? false,
            hoursOperating: HoursOperating.fromMap(data['hoursOperating']),
        });
    }
    static clone(x: Stand): Stand {
        return new Stand({
            address: x.address,
            admins: x.admins,
            allowServiceReservations: x.allowServiceReservations,
            app: x.app,
            associates: x.associates,
            currency: x.currency,
            documentId: x.documentId,
            enableGuideReservations: x.enableGuideReservations,
            enablePayNow: x.enablePayNow,
            enableUserReservations: x.enableUserReservations,
            guideReservationFee: x.guideReservationFee,
            guides: x.guides,
            masterAllowServiceReservations: x.masterAllowServiceReservations,
            masterEnablePayNow: x.masterEnablePayNow,
            masterEnableReservations: x.masterEnableReservations,
            name: x.name,
            passCcChargesToUser: x.passCcChargesToUser,
            phone: x.phone,
            profileUrl: x.profileUrl,
            reservationFee: x.reservationFee,
            reservationFeeTierOne: x.reservationFeeTierOne,
            reservationFeeTierThree: x.reservationFeeTierThree,
            reservationFeeTierTwo: x.reservationFeeTierTwo,
            salesTax: x.salesTax,
            serviceGuideReservationFee: x.serviceGuideReservationFee,
            serviceReservationFee: x.serviceReservationFee,
            serviceReservationFeeTierOne: x.serviceReservationFeeTierOne,
            serviceReservationFeeTierThree: x.serviceReservationFeeTierThree,
            serviceReservationFeeTierTwo: x.serviceReservationFeeTierTwo,
            standNotificationToken: x.standNotificationToken,
            standStripeKey: x.standStripeKey,
            website: x.website,
            hours: x.hours,
            isSelected: x.isSelected,
            allowTouchlessDelivery: x.allowTouchlessDelivery,
            enforceTouchlessDelivery: x.enforceTouchlessDelivery,
            hoursOperating: x.hoursOperating,
        });
    }
}

class Hours implements IHours {
    monday: {
        open: string;
        closed: string;
    };
    tuesday: {
        open: string;
        closed: string;
    };
    wednesday: {
        open: string;
        closed: string;
    };
    thursday: {
        open: string;
        closed: string;
    };
    friday: {
        open: string;
        closed: string;
    };
    saturday: {
        open: string;
        closed: string;
    };
    sunday: {
        open: string;
        closed: string;
    };

    constructor(x: IHours) {
        this.monday = x.monday;
        this.friday = x.friday;
        this.saturday = x.saturday;
        this.sunday = x.sunday;
        this.thursday = x.thursday;
        this.tuesday = x.thursday;
        this.wednesday = x.wednesday;
    }

    static fromMap(map: Record<string, any>): Hours {
        if (!map)
            return new Hours({
                monday: { open: '09:00', closed: '17:00' },
                friday: { open: '09:00', closed: '17:00' },
                saturday: { open: '09:00', closed: '17:00' },
                sunday: { open: '09:00', closed: '17:00' },
                thursday: { open: '09:00', closed: '17:00' },
                tuesday: { open: '09:00', closed: '17:00' },
                wednesday: { open: '09:00', closed: '17:00' },
            });

        return new Hours({
            monday: {
                open: map['monday']?.open ?? '09:00',
                closed: map['monday']?.close ?? '17:00',
            },
            friday: {
                open: map['friday']?.open ?? '09:00',
                closed: map['friday']?.close ?? '17:00',
            },
            saturday: {
                open: map['saturday']?.open ?? '09:00',
                closed: map['saturday']?.close ?? '17:00',
            },
            sunday: {
                open: map['sunday']?.open ?? '09:00',
                closed: map['sunday']?.close ?? '17:00',
            },
            thursday: {
                open: map['thursday']?.open ?? '09:00',
                closed: map['thursday']?.close ?? '17:00',
            },
            tuesday: {
                open: map['tuesday']?.open ?? '09:00',
                closed: map['tuesday']?.close ?? '17:00',
            },
            wednesday: {
                open: map['wednesday']?.open ?? '09:00',
                closed: map['wednesday']?.close ?? '17:00',
            },
        });
    }
}

interface IStand {
    isSelected: boolean;
    address: string;
    admins: string[];
    allowServiceReservations: boolean;
    app: string;
    associates: string[];
    currency: string;
    documentId: string;
    enableGuideReservations: boolean;
    enablePayNow: boolean;
    enableUserReservations: boolean;
    guideReservationFee: number;
    guides: string[];
    masterAllowServiceReservations: boolean;
    masterEnablePayNow: boolean;
    masterEnableReservations: boolean;
    name: string;
    passCcChargesToUser: boolean;
    phone: string;
    profileUrl: string;
    reservationFee: number;
    reservationFeeTierOne: number;
    reservationFeeTierThree: number;
    reservationFeeTierTwo: number;
    salesTax: number;
    serviceGuideReservationFee: number;
    serviceReservationFee: number;
    serviceReservationFeeTierOne: number;
    serviceReservationFeeTierThree: number;
    serviceReservationFeeTierTwo: number;
    standNotificationToken: string;
    standStripeKey: string;
    website: string;
    hours: Hours;
    allowTouchlessDelivery: boolean;
    enforceTouchlessDelivery: boolean;
    hoursOperating: HoursOperating;
}

interface IHours {
    monday: {
        open: string;
        closed: string;
    };
    tuesday: {
        open: string;
        closed: string;
    };
    wednesday: {
        open: string;
        closed: string;
    };
    thursday: {
        open: string;
        closed: string;
    };
    friday: {
        open: string;
        closed: string;
    };
    saturday: {
        open: string;
        closed: string;
    };
    sunday: {
        open: string;
        closed: string;
    };
}
