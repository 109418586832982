import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Card, TextField, Button, Theme, Typography } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { firebase } from '../firebase';
import { AuthContext } from '../models_context/AuthContext';
import { useHistory } from 'react-router';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useSnackbar } from 'notistack';
import ZTextField from '../components/ZTextField';
import { makeStyles } from '@material-ui/core/styles';

interface Props {}

type FormData = {
    email: string;
    password: string;
};

const SignInPage: React.FC<Props> = () => {
    const { dispatch } = React.useContext(AuthContext);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    let history = useHistory();

    const onSubmit = async (data: FormData) => {
        let user;
        try {
            const res = await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
            if (res) user = res.user;
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message);
        }

        if (user) {
            dispatch({
                type: 'SET_AUTENTICATION',
                payload: true,
            });
            history.push('/dashboard-summary');
        } else {
            dispatch({
                type: 'SET_AUTENTICATION',
                payload: false,
            });
        }
    };

    const onGoogleSignIn = async () => {
        let user;
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            const authResult = await firebase.auth().signInWithPopup(provider);
            if (authResult.credential) {
                const res = await firebase.auth().signInWithCredential(authResult.credential);
                if (res) user = res.user;
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message);
        }

        if (user) {
            dispatch({
                type: 'SET_AUTENTICATION',
                payload: true,
            });
            history.push('/dashboard-summary');
        } else {
            dispatch({
                type: 'SET_AUTENTICATION',
                payload: false,
            });
        }
    };

    const { register, errors, handleSubmit, control } = useForm<FormData>({});

    return (
        <Grid container>
            <Grid item xs={4} className={classes.image}></Grid>
            <Grid item xs={8}>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                    <Typography className={classes.pageTitle}>
                        Welcome to BaitFinder Admin
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <ZTextField
                                name='email'
                                rules={{ required: 'This field is required' }}
                                control={control}
                                error={errors?.email ? true : false}
                                helperText={errors.email?.message}
                                label='Email'
                                defaultValue=''
                                className='text-field'></ZTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <ZTextField
                                name='password'
                                rules={{ required: 'This field is required' }}
                                control={control}
                                error={errors?.password ? true : false}
                                helperText={errors.password?.message}
                                label='Password'
                                defaultValue=''
                                type='password'
                                className='text-field'></ZTextField>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                className={classes.signInBtn}
                                type='submit'
                                variant='outlined'
                                color='primary'>
                                Sign In
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                className={classes.signInGoogleBtn}
                                variant='contained'
                                onClick={onGoogleSignIn}
                                color='primary'>
                                Sign In With Google
                            </Button>
                        </Grid>
                    </Grid>

                    <Typography className={classes.pageVisitHeading}>
                        You may also visit our regular sites
                    </Typography>

                    <Grid container justify='center'>
                        <Grid md={6} container justify='center'>
                            <a
                                className={classes.siteLinkBaitshop}
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://www.baitfinder.com/baitshop'>
                                <span className={classes.siteLinkText}>BaitShop</span>
                            </a>
                        </Grid>

                        <Grid md={6} container justify='center'>
                            <a
                                className={classes.siteLinkAngler}
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://www.baitfinder.com/angler'>
                                <span className={classes.siteLinkText}>Anglers</span>
                            </a>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    pageTitle: {
        textAlign: 'center',
        fontSize: '28px',
        fontWeight: 600,
        marginBottom: '20px',
    },

    pageVisitHeading: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 500,
        marginTop: '40px',
        marginBottom: '20px',
    },

    form: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        maxWidth: '500px',
    },

    image: {
        backgroundImage: `url('/images/baitshop_landing.jpg')`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100%',
    },

    signInBtn: {
        width: '100%',
        margin: '12px 0',
    },
    signInGoogleBtn: {
        width: '100%',
        margin: '12px 0',
    },

    siteLinkAngler: {
        backgroundImage: `url('/images/landing_link_angler.jpg')`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '120px',
        width: '90%',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
    },
    siteLinkBaitshop: {
        backgroundImage: `url('/images/landing_link_baitshop.jpg')`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '120px',
        width: '90%',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
    },

    siteLinkText: {
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '10px',
        padding: '8px',
    },
}));

SignInPage.defaultProps = {};

export default SignInPage;
