export function NumberParser({
    number,
    fixed,
}: {
    number: string | number;
    fixed?: number;
}): number {
    if (Number.isNaN(parseFloat(number.toString()))) {
        return 0;
    } else {
        return parseFloat(parseFloat(number.toString()).toFixed(fixed ?? 2));
    }
}
