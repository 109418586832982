import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { Hidden, Grid, Button } from '@material-ui/core';
import Carousel from '@brainhubeu/react-carousel';
import '../styles/brainhubeu_react_carousel.css';
import breakpoint from '../app_utils/Breakpoints';
import ZText from './ZText';

interface ICarouselItem {
    image: string;
    body: string;
}

interface Props {
    CarouselItems: ICarouselItem[];
}

const cssCarouselWrapper = css`
    max-width: 1080px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    margin-top: 15px;
`;
const cssButtonGrid = css`
    margin-top: 25px;

    .btn {
        margin-left: 10px;
        margin-right: 10px;
        width: 100px;
    }
`;

const CarouselItem: React.FC<{ image: string; body: string }> = ({ image, body }) => {
    return (
        <div
            css={css`
                max-width: 400px;
                padding-bottom: 20px;
            `}>
            <img
                css={css`
                    max-height: 350px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;

                    @media ${breakpoint.md} {
                        max-height: 500px;
                    }
                `}
                src={image}
                alt=''
            />

            <ZText
                textAlign='center'
                css={css`
                    /* margin-top: -5px; */
                `}>
                {body}
            </ZText>
        </div>
    );
};

const ResponsiveCarouselNext: React.FC<Props> = ({ CarouselItems }) => {
    const [value, setValue] = React.useState<number>(0);
    const onChange = (value: number) => {
        setValue(value);
        console.log(value);
    };
    return (
        <div>
            <Hidden>
                <Grid container justify='center' item md={12} css={cssButtonGrid}>
                    <Button
                        className='btn'
                        variant='outlined'
                        onClick={() => setValue((prev) => prev - 1)}>
                        Previous
                    </Button>
                    <Button
                        className='btn'
                        variant='outlined'
                        onClick={() => setValue((prev) => prev + 1)}>
                        Next
                    </Button>
                </Grid>
            </Hidden>

            <Grid container justify='center' css={cssCarouselWrapper}>
                <Grid item xs={10} md={10}>
                    <Carousel
                        value={value}
                        animationSpeed={1000}
                        infinite
                        stopAutoPlayOnHover
                        draggable={false}
                        onChange={onChange}
                        slidesPerPage={2}
                        breakpoints={{
                            576: {
                                slidesPerPage: 1,
                                slidesPerScroll: 1,
                                clickToChange: true,
                                animationSpeed: 1000,
                                infinite: true
                            }
                        }}>
                        {CarouselItems.map((item, index) => (
                            <CarouselItem key={index} body={item.body} image={item.image} />
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </div>
    );
};

ResponsiveCarouselNext.defaultProps = {};

export default ResponsiveCarouselNext;
