import * as React from 'react';
import {
    Grid,
    Button,
    Card,
    Divider,
    makeStyles,
    Theme,
    Typography,
    Container,
} from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { AdminContext } from '../../models_context/AdminContext';
import { Stand } from '../../models/stand';
import { useForm } from 'react-hook-form';
import ZTextField from '../../components/ZTextField';
import { FirestoreService } from '../../models_services/FirestoreService';
import ZCheckBoxTile from '../../components/ZCheckBoxTile';
import { NumberParser } from '../../app_utils/numberParse';

import ZDialogForm from '../../components/ZDialogForm';
import ZFormTimePicker from '../../components/ZFormTimePicker';

interface Props {}

type FormData = {
    allowServiceReservations: boolean;
    allowTouchlessDelivery: boolean;
    enableGuideReservations: boolean;
    enablePayNow: boolean;
    enableUserReservations: boolean;
    enforceTouchlessDelivery: boolean;
    guideReservationFee: number;
    passCcChargesToUser: boolean;
    phone: string;
    reservationFee: number;
    salesTax: number;
    serviceGuideReservationFee: number;
    serviceReservationFee: number;
    website: string;
};

const DashboardSummaryPage: React.FC<Props> = () => {
    const { state, dispatch } = React.useContext(AdminContext);
    const [openSummaryForm, setOpenSummaryForm] = React.useState(false);
    const [openPaymentForm, setOpenPaymentFrom] = React.useState(false);
    const [openOperatingHours, setOpenOperatingHours] = React.useState(false);

    const classes = useStyles();
    const [stand, setStand] = React.useState<Stand>();
    const { handleSubmit, errors, control } = useForm<FormData>({});

    React.useEffect(() => {
        if (state.stand) setStand(Stand.clone(state.stand));
        console.log('state stand *************', stand);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.stand]);

    const onSubmitSummary = handleSubmit((data: FormData) => {
        console.log('from', data);
        if (stand?.documentId) {
            FirestoreService.updateStand(stand?.documentId, {
                website: data.website,
                phone: data.phone,
            });
            setOpenSummaryForm(false);
        }
    });

    const onSubmitPayment = handleSubmit((data: FormData) => {
        console.log('from', data);
        if (stand?.documentId) {
            FirestoreService.updateStand(stand?.documentId, {
                allowServiceReservations: data.allowServiceReservations,
                allowTouchlessDelivery: data.allowTouchlessDelivery,
                enableGuideReservations: data.enableGuideReservations,
                enablePayNow: data.enablePayNow,
                enableUserReservations: data.enableUserReservations,
                enforceTouchlessDelivery: data.enforceTouchlessDelivery,
                passCcChargesToUser: data.passCcChargesToUser,

                salesTax: NumberParser({ number: data.salesTax }),

                guideReservationFee: NumberParser({ number: data.guideReservationFee }),

                reservationFee: NumberParser({ number: data.reservationFee }),

                serviceGuideReservationFee: NumberParser({
                    number: data.serviceGuideReservationFee,
                }),

                serviceReservationFee: NumberParser({ number: data.serviceReservationFee }),
            });
            setOpenPaymentFrom(false);
        }
    });

    return (
        <>
            {openSummaryForm && stand && (
                <ZDialogForm
                    title={'Edit Summary'}
                    message={
                        <>
                            <form onSubmit={onSubmitSummary} className={classes.form}>
                                <ZTextField
                                    name='website'
                                    control={control}
                                    error={errors.website ? true : false}
                                    label='Website'
                                    defaultValue={stand.website}></ZTextField>

                                <ZTextField
                                    name='phone'
                                    control={control}
                                    error={errors.phone ? true : false}
                                    label='Phone'
                                    defaultValue={stand.phone}></ZTextField>
                            </form>
                        </>
                    }
                    open={openSummaryForm}
                    submitForm={() => onSubmitSummary()}
                    cancelForm={() => setOpenSummaryForm(false)}
                />
            )}

            {openOperatingHours && stand && (
                <ZDialogForm
                    title={'Operating Hours'}
                    message={
                        <>
                            <form className={classes.form}>
                                <ZFormTimePicker
                                    openDialogCallback={setOpenOperatingHours}
                                    stand={Stand.clone(stand)}></ZFormTimePicker>
                            </form>
                        </>
                    }
                    open={openOperatingHours}
                />
            )}

            {openPaymentForm && stand && (
                <ZDialogForm
                    title={'Edit Payment'}
                    message={
                        <>
                            <form onSubmit={onSubmitPayment} className={classes.form}>
                                <ZTextField
                                    name='salesTax'
                                    control={control}
                                    rules={{
                                        required: true,
                                        pattern: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                                    }}
                                    helperText={'This should be a number'}
                                    error={errors.salesTax ? true : false}
                                    label='Sales tax (Percent)'
                                    type='number'
                                    defaultValue={stand.salesTax.toString()}></ZTextField>

                                <ZTextField
                                    name='reservationFee'
                                    control={control}
                                    error={errors.reservationFee ? true : false}
                                    rules={{
                                        required: true,
                                        pattern: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                                    }}
                                    label='Reservation Fee'
                                    defaultValue={stand.reservationFee.toString()}></ZTextField>

                                <ZTextField
                                    name='serviceReservationFee'
                                    control={control}
                                    error={errors.serviceReservationFee ? true : false}
                                    rules={{
                                        required: true,
                                        pattern: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                                    }}
                                    label='Service Reservation Fee'
                                    defaultValue={stand.serviceReservationFee.toString()}></ZTextField>

                                <ZTextField
                                    name='serviceGuideReservationFee'
                                    control={control}
                                    error={errors.serviceGuideReservationFee ? true : false}
                                    rules={{
                                        required: true,
                                        pattern: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                                    }}
                                    label='Service Guide Reservation Fee'
                                    defaultValue={stand.serviceGuideReservationFee.toString()}></ZTextField>

                                <ZTextField
                                    name='guideReservationFee'
                                    control={control}
                                    error={errors.guideReservationFee ? true : false}
                                    rules={{
                                        required: true,
                                        pattern: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                                    }}
                                    label='Guide Reservation Fee'
                                    defaultValue={stand.guideReservationFee.toString()}></ZTextField>

                                <ZCheckBoxTile
                                    name='enableGuideReservations'
                                    control={control}
                                    defaultValue={stand?.enableGuideReservations}
                                    label='Enable guide reservation'></ZCheckBoxTile>

                                <ZCheckBoxTile
                                    name='enableUserReservations'
                                    control={control}
                                    defaultValue={stand?.enableUserReservations}
                                    label='Enable user reservation'></ZCheckBoxTile>

                                <ZCheckBoxTile
                                    name='passCcChargesToUser'
                                    control={control}
                                    defaultValue={stand?.passCcChargesToUser}
                                    label='Pass credit card charges to customers'></ZCheckBoxTile>

                                <ZCheckBoxTile
                                    name='enablePayNow'
                                    control={control}
                                    defaultValue={stand?.enablePayNow}
                                    label='Enable Pay Now'></ZCheckBoxTile>
                                <ZCheckBoxTile
                                    name='allowServiceReservations'
                                    control={control}
                                    defaultValue={stand?.allowServiceReservations}
                                    label='Allow Service Reservation'></ZCheckBoxTile>
                                <ZCheckBoxTile
                                    name='allowTouchlessDelivery'
                                    control={control}
                                    defaultValue={stand?.allowTouchlessDelivery}
                                    label='Allow Touchless Delivery'></ZCheckBoxTile>

                                <ZCheckBoxTile
                                    name='enforceTouchlessDelivery'
                                    control={control}
                                    defaultValue={stand?.enforceTouchlessDelivery}
                                    label='Enforce Touchless Delivery'></ZCheckBoxTile>
                            </form>
                        </>
                    }
                    open={openPaymentForm}
                    submitForm={() => onSubmitPayment()}
                    cancelForm={() => setOpenPaymentFrom(false)}
                />
            )}
            {stand && (
                <>
                    <Container maxWidth='xl'>
                        <Grid className={classes.title}>{stand.name}</Grid>
                        <Grid container>
                            <Grid item lg={4}>
                                <Card className={classes.card}>
                                    <Grid container justify='space-between'>
                                        <Typography>Summary</Typography>
                                        <Button
                                            onClick={() => setOpenSummaryForm(true)}
                                            size='small'
                                            color='primary'
                                            variant='outlined'>
                                            Edit
                                        </Button>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Stripe Account Linked:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.standStripeKey ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Website:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.website}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Phone:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.phone}
                                        </Typography>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid item lg={4}>
                                <Card className={classes.card}>
                                    <Grid container justify='space-between'>
                                        <Typography>Edit Opening Hours</Typography>
                                        <Button
                                            onClick={() => setOpenOperatingHours(true)}
                                            size='small'
                                            color='primary'
                                            variant='outlined'>
                                            Edit
                                        </Button>
                                    </Grid>

                                    <Grid container justify='space-between'>
                                        <DayTimeHours
                                            day='Monday'
                                            timeOpen={stand.hours.monday.open}
                                            timeClose={stand.hours.monday.closed}></DayTimeHours>
                                        <DayTimeHours
                                            day='Tuesday'
                                            timeOpen={stand.hours.tuesday.open}
                                            timeClose={stand.hours.tuesday.closed}></DayTimeHours>
                                        <Divider />
                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <DayTimeHours
                                            day='Wednesday'
                                            timeOpen={stand.hours.wednesday.open}
                                            timeClose={stand.hours.wednesday.closed}></DayTimeHours>
                                        <DayTimeHours
                                            day='Thursday'
                                            timeOpen={stand.hours.thursday.open}
                                            timeClose={stand.hours.thursday.closed}></DayTimeHours>
                                        <Divider />
                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <DayTimeHours
                                            day='Friday'
                                            timeOpen={stand.hours.friday.open}
                                            timeClose={stand.hours.friday.closed}></DayTimeHours>
                                        <DayTimeHours
                                            day='Saturday'
                                            timeOpen={stand.hours.saturday.open}
                                            timeClose={stand.hours.saturday.closed}></DayTimeHours>
                                        <Divider />
                                    </Grid>
                                    <Grid container justify='space-between'>
                                        <DayTimeHours
                                            day='Sunday'
                                            timeOpen={stand.hours.sunday.open}
                                            timeClose={stand.hours.sunday.closed}></DayTimeHours>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid item lg={4}>
                                <Card className={classes.card}>
                                    <Grid container justify='space-between'>
                                        <Typography>BaitFinder Payment System</Typography>
                                        <Button
                                            onClick={() => setOpenPaymentFrom(true)}
                                            size='small'
                                            color='primary'
                                            variant='outlined'>
                                            Edit
                                        </Button>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Enable guide reservation:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.enableGuideReservations ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Enable user reservation:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.enableUserReservations ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Pass credit card charges to customers:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.passCcChargesToUser ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Enable Pay Now:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.enablePayNow ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Allow Service Reservation:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.allowServiceReservations ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Allow Touchless Delivery:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.allowTouchlessDelivery ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Enforce Touchless Delivery:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.enforceTouchlessDelivery ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>

                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Sales tax (Percent):
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            {stand.salesTax}%
                                        </Typography>
                                    </Grid>

                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Reservation Fee:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            ${stand.reservationFee}
                                        </Typography>
                                    </Grid>

                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Service Guide Reservation Fee:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            ${stand.serviceGuideReservationFee}
                                        </Typography>
                                    </Grid>

                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Service Reservation Fee:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            ${stand.serviceReservationFee}
                                        </Typography>
                                    </Grid>

                                    <Grid container className={classes.summaryItemWrapper}>
                                        <Typography className={classes.summaryTitle}>
                                            Guide Reservation Fee:
                                        </Typography>
                                        <Typography className={classes.summarySubTitle}>
                                            ${stand.guideReservationFee}
                                        </Typography>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '24px',
        color: '#1b3a57',
        fontWeight: 700,
        marginBottom: '20px',
    },
    form: {
        minWidth: '500px',
    },
    card: {
        padding: '12px',
        marginRight: '20px',
        maxWidth: '600px',
    },
    summaryItemWrapper: {
        marginTop: '14px',
    },
    summaryTitle: {
        fontSize: '14px',
        marginRight: '10px',
    },
    summarySubTitle: {
        fontSize: '14px',
        fontWeight: 700,
    },
    dayTimeWrapper: {
        marginTop: '14px',
    },
    dayTimeDay: {
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '3px',
    },
    dayTimeOpenClose: {
        fontSize: '14px',
        marginBottom: '3px',
        marginRight: '12px',
    },
    dayTimeValue: {
        fontSize: '14px',
        marginBottom: '3px',
    },
    divider: {},
}));

DashboardSummaryPage.defaultProps = {};

export default DashboardSummaryPage;

const DayTimeHours = ({
    day,
    timeOpen,
    timeClose,
}: {
    day: string;
    timeOpen: string;
    timeClose: string;
}) => {
    const classes = useStyles();
    return (
        <Grid item xs={6} className={classes.dayTimeWrapper}>
            <Typography className={classes.dayTimeDay}> {day}</Typography>

            <Grid container>
                <Typography className={classes.dayTimeOpenClose}> Open:</Typography>
                <Typography className={classes.dayTimeValue}> {timeOpen}</Typography>
            </Grid>

            <Grid container>
                <Typography className={classes.dayTimeOpenClose}> Closed:</Typography>
                <Typography className={classes.dayTimeValue}> {timeClose}</Typography>
            </Grid>
        </Grid>
    );
};
