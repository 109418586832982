import React, { Dispatch } from 'react';

import { db, firebase } from '../firebase';
import { FirestoreService } from '../models_services/FirestoreService';
import { Stock } from '../models/stock';
import { Bait } from '../models/bait';
import { Stand } from '../models/stand';
import { Reservation } from '../models/reservation';
import { Report as FishingReport } from '../models/report';
import { BaitReport } from '../models/baitReport';

type State = {
    baits: Bait[];
    baitsFresh: Bait[];
    baitsSalt: Bait[];
    isAuthenticated: boolean;
    isloading: boolean;
    stand: Stand | null;
    stands: Stand[];
    stocks: Stock[];
    fishingReports: FishingReport[];
    reservations: Reservation[];
    reservationSelected: Reservation | null;
    reportDeliveries: Reservation[];
    reportBaits: BaitReport[];
};

const initialState: State = {
    isAuthenticated: false,
    isloading: true,
    stand: null,
    stocks: [],
    baits: [],
    baitsSalt: [],
    baitsFresh: [],
    stands: [],
    fishingReports: [],
    reservations: [],
    reportDeliveries: [],
    reportBaits: [],
    reservationSelected: null,
};

type Actions =
    | { type: 'SET_STAND'; payload: Stand | null }
    | { type: 'SET_STANDS'; payload: Stand[] }
    | { type: 'SET_SELECTED_STAND_BY_INDEX'; payload: number }
    | { type: 'SET_STOCKS'; payload: Stock[] }
    | { type: 'SET_FISHING_REPORTS'; payload: FishingReport[] }
    | { type: 'SET_BAITS'; payload: Bait[] }
    | { type: 'SET_RESERVATIONS'; payload: Reservation[] }
    | { type: 'SET_REPORT_DELIVERIES'; payload: Reservation[] }
    | { type: 'SET_REPORT_BAITS'; payload: BaitReport[] }
    | { type: 'SET_RESERVATION_SELECTED'; payload: Reservation };

interface IContextProps {
    state: State;
    dispatch: Dispatch<Actions>;
}

const AdminReducer = (state: State, action: Actions): State => {
    switch (action.type) {
        case 'SET_STAND':
            return { ...state, stand: action.payload };

        case 'SET_STANDS':
            return { ...state, stands: action.payload };

        case 'SET_SELECTED_STAND_BY_INDEX':
            state.stands.forEach((x) => (x.isSelected = false));
            state.stands[action.payload].isSelected = true;
            state.stand = state.stands[action.payload];
            return { ...state };

        case 'SET_STOCKS':
            return { ...state, stocks: action.payload };

        case 'SET_FISHING_REPORTS':
            return { ...state, fishingReports: action.payload };

        case 'SET_REPORT_DELIVERIES':
            return { ...state, reportDeliveries: action.payload };

        case 'SET_REPORT_BAITS':
            return { ...state, reportBaits: action.payload };

        case 'SET_BAITS':
            const _baits = action.payload;
            const _baitsSalt: Bait[] = [];
            const _baitsFresh: Bait[] = [];
            state.stocks.forEach((stock) => {
                _baits.forEach((_bait, index) => {
                    if (_bait.documentId === stock.stockId) {
                        _baits.splice(index, 1);
                    }
                });
            });
            _baits.forEach((bait) => {
                if (bait.salt) {
                    _baitsSalt.push(bait);
                } else {
                    _baitsFresh.push(bait);
                }
            });
            return { ...state, baits: _baits, baitsFresh: _baitsFresh, baitsSalt: _baitsSalt };

        case 'SET_RESERVATIONS':
            return { ...state, reservations: action.payload };

        case 'SET_RESERVATION_SELECTED':
            return { ...state, reservationSelected: action.payload };

        default:
            return state;
    }
};

export const AdminContext = React.createContext({} as IContextProps);

export function AdminContextProvider(props: any) {
    const [state, dispatch] = React.useReducer(AdminReducer, initialState);

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(async (authUser) => {
            if (authUser) {
                const email = await firebase.auth().currentUser?.email;

                if (email) {
                    const unsubscribe = FirestoreService.streamStands(email, {
                        next: (querySnapshot) => {
                            let stands: Stand[] = [];

                            querySnapshot.docs.forEach((docSnapshot) => {
                                const res = Stand.fromFirestore(docSnapshot);
                                if (res) stands.push(res);
                            });

                            if (stands.length > 0) {
                                stands[0].isSelected = true;
                            }

                            dispatch({
                                type: 'SET_STANDS',
                                payload: stands,
                            });

                            dispatch({
                                type: 'SET_STAND',
                                payload: stands[0],
                            });

                            console.log('Baitstands', stands);
                        },
                        error: (err) => console.log(err),
                    });
                    return unsubscribe;
                }
            }
        });
    }, []);

    React.useEffect(() => {
        const id = state?.stand?.documentId ?? '';
        const unsubscribe = FirestoreService.streamStocks(id, {
            next: (querySnapshot) => {
                let stocks: Stock[] = [];
                querySnapshot.docs.forEach((docSnapshot) => {
                    const res = Stock.fromFirestore(docSnapshot);
                    if (res) stocks.push(res);
                });

                console.log(stocks);

                dispatch({ type: 'SET_STOCKS', payload: stocks });
            },
            error: (err) => console.log(err),
        });
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.stand?.documentId]);

    React.useEffect(() => {
        const id = state?.stand?.documentId ?? '';
        const unsubscribe = FirestoreService.streamReservations(id, {
            next: (querySnapshot) => {
                let reservations: Reservation[] = [];
                querySnapshot.docs.forEach((docSnapshot) => {
                    const res = Reservation.fromFirestore(docSnapshot);
                    if (res) reservations.push(res);
                });

                // NOTE Fix to sort newest reservation first
                reservations.sort(function (a, b) {
                    return b.timestamp < a.timestamp ? -1 : 1;
                });

                dispatch({ type: 'SET_RESERVATIONS', payload: reservations });
            },
            error: (err) => console.log(err),
        });
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.stand?.documentId]);

    React.useEffect(() => {
        const id = state?.stand?.documentId ?? '';
        const unsubscribe = FirestoreService.streamFishingReports(id, {
            next: (querySnapshot) => {
                let reports: FishingReport[] = [];
                querySnapshot.docs.forEach((docSnapshot) => {
                    const res = FishingReport.fromFirestore(docSnapshot);
                    if (res) reports.push(res);
                });
                dispatch({ type: 'SET_FISHING_REPORTS', payload: reports });
            },
            error: (err) => console.log(err),
        });
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.stand?.documentId]);

    React.useEffect(() => {
        const id = state?.stand?.documentId ?? '';
        const unsubscribe = FirestoreService.streamReports(id, {
            next: (querySnapshot) => {
                let reportsReservations: Reservation[] = [];
                querySnapshot.docs.forEach((docSnapshot) => {
                    const res = Reservation.fromFirestore(docSnapshot);
                    if (res) reportsReservations.push(res);
                });

                // NOTE Fix to sort newest reservation first
                reportsReservations.sort(function (a, b) {
                    return b.timestamp < a.timestamp ? -1 : 1;
                });

                dispatch({ type: 'SET_REPORT_DELIVERIES', payload: reportsReservations });

                let reportBaits: BaitReport[] = [];
                let reportBaitStrings: string[] = [];
                reportsReservations.forEach((report) => {
                    report.items.forEach((i) => {
                        if (!reportBaitStrings.includes(i.name)) {
                            reportBaitStrings.push(i.name);
                            reportBaits.push({
                                name: i.name,
                                viewType: '',
                                items: [
                                    {
                                        type: i.baitType,
                                        unit: i.pricing.unit ?? '',
                                        date: report.pickUpDateTimeStr,
                                        name: i.name,
                                        quantity: i.quantity,
                                    },
                                ],
                                itemsGroup: [],
                            });
                        } else {
                            const index = reportBaitStrings.indexOf(i.name);
                            reportBaits[index].items.push({
                                type: i.baitType,
                                unit: i.pricing.unit,
                                date: report.pickUpDateTimeStr,
                                name: i.name,
                                quantity: i.quantity,
                            });
                        }
                    });
                });

                reportBaits.forEach((r) => {
                    let reportBaitGroupItemsStrings: string[] = [];

                    r.items.forEach((x) => {
                        if (!reportBaitGroupItemsStrings.includes(x.unit)) {
                            reportBaitGroupItemsStrings.push(x.unit);
                            r.itemsGroup.push({
                                name: x.unit,
                                total: x.quantity,
                                items: [
                                    {
                                        date: x.date,
                                        name: x.name,
                                        quantity: x.quantity,
                                        type: x.type,
                                        unit: x.unit,
                                    },
                                ],
                            });
                        } else {
                            const index = reportBaitGroupItemsStrings.indexOf(x.unit);
                            r.itemsGroup[index].total += x.quantity;
                            r.itemsGroup[index].items.push({
                                date: x.date,
                                name: x.unit,
                                quantity: x.quantity,
                                type: x.type,
                                unit: x.unit,
                            });
                        }
                    });
                });

                dispatch({ type: 'SET_REPORT_BAITS', payload: reportBaits });

                console.log('Report Deliveries', reportsReservations);
                console.log('Report Bait Report', reportBaits);
            },
            error: (err) => console.log(err),
        });
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.stand?.documentId]);

    return (
        <AdminContext.Provider value={{ state, dispatch }}>{props.children}</AdminContext.Provider>
    );
}
