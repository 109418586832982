import React from 'react';
import { Grid, Button, Card, Divider } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FirestoreService } from '../models_services/FirestoreService';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    prices: any[];
    title: string;
    type: string;
    stockId: string;
    setPriceShowForm: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedPriceObject: React.Dispatch<React.SetStateAction<{}>>;
}

const ZBaitPricingCard: React.FC<Props> = ({
    prices,
    title,
    setPriceShowForm,
    setSelectedPriceObject,
    type,
    stockId,
}) => {
    return (
        <div css={cssStyles}>
            <Card className='card'>
                <Grid
                    container
                    justify='space-between'
                    alignItems='center'
                    className='card-heading'>
                    <Grid item xs={5}>
                        <p className='add-title'>{title}</p>
                    </Grid>

                    <Grid item xs={4}>
                        <Button
                            onClick={() => {
                                setSelectedPriceObject({
                                    updateType: 'ADD',
                                    index: 0,
                                    type: type,
                                    prices: [...prices],
                                });
                                setPriceShowForm(true);
                            }}
                            variant='outlined'
                            color='primary'
                            fullWidth
                            className='button-title'>
                            Add Price
                        </Button>
                    </Grid>
                </Grid>

                {prices && (
                    <div>
                        <Divider className='divider' />
                        {prices.map((price: any, index: number) => (
                            <Grid key={index} container justify='space-between' alignItems='center'>
                                <Grid item xs={7}>
                                    {`$${price.price.toFixed(2)}${price.currency}/${price.unit}`}
                                </Grid>
                                <Grid item xs={5} container justify='flex-end'>
                                    <Grid item xs={6}>
                                        <Button
                                            onClick={() => {
                                                setSelectedPriceObject({
                                                    updateType: 'EDIT',
                                                    index: index,
                                                    type: type,
                                                    prices: [...prices],
                                                });
                                                setPriceShowForm(true);
                                            }}
                                            variant='outlined'
                                            color='primary'
                                            fullWidth
                                            className='button'>
                                            Edit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            onClick={() => {
                                                prices.splice(index, 1);
                                                const data = {
                                                    [type]: {
                                                        pricing: [...prices],
                                                    },
                                                };

                                                FirestoreService.updateBait(stockId, data);
                                            }}
                                            variant='outlined'
                                            color='secondary'
                                            fullWidth
                                            className='button'>
                                            Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                )}
            </Card>
        </div>
    );
};

const cssStyles = css`
    .page-title {
        color: #1b3a57;
        font-size: 18px;
        text-align: center;
        line-height: 20px;
        font-weight: 700;
    }

    .title-divider {
        margin: 20px 0px;
    }
    .card-heading {
        padding: 0px 10px 0px 5px;

        .add-title {
            font-size: 14px;
            padding: 0;
            text-align: left;
            letter-spacing: 0.25px;
            width: 100%;
            color: #1a73e8;
            font-weight: 700;
        }

        .button-title {
            font-size: 11px;
            margin-left: 5px;
        }
    }

    .button {
        font-size: 11px;
        max-width: 50px;
    }

    .divider {
        margin: 10px 0px;
    }

    .card {
        margin: 5px 5px 20px 5px;
        padding: 10px;
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    }

    .button {
        margin: 5px;
    }

    .drawer {
        width: 380px;
        height: 100%;
        padding: 10px;
        overflow: auto;
    }

    .MuiTypography-body1 {
        font-size: 0.95rem;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
    }
`;

ZBaitPricingCard.defaultProps = {};

export default ZBaitPricingCard;
