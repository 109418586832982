import * as React from 'react';
import { Grid, Button, Card, Divider } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

interface Props {}

const cssPaper = css`
    width: 1200px;
    height: 1080px;
    padding: 10px;

    .grid-columns {
        padding: 0 15px;
    }

    .text-field {
        margin: 15px 0px;
    }
`;

const cssStyles = css`
    .button-grid {
    }

    .divider {
        margin-bottom: 40px;
    }
    .btn {
        margin-right: 20px;
    }

    .btn-active {
        color: blueviolet;
        margin-right: 20px;
    }
`;

const DashboardNotificationsPage: React.FC<Props> = () => {
    const [section, setSection] = React.useState<string | null>('Bait');

    return (
        <Grid container direction='column' item xs={12} css={cssStyles}>
            <Grid>
                <Button
                    className={`${section === 'Bait' ? 'btn-active' : 'btn'}`}
                    onClick={() => setSection('Bait')}>
                    Bait
                </Button>
                <Button
                    className={`${section === 'Info' ? 'btn-active' : 'btn'}`}
                    onClick={() => setSection('Info')}>
                    Info
                </Button>
                <Button
                    className={`${section === 'Stripe' ? 'btn-active' : 'btn'}`}
                    onClick={() => setSection('Stripe')}>
                    Stripe
                </Button>
            </Grid>
            <Divider className='divider' />
        </Grid>
    );
};

DashboardNotificationsPage.defaultProps = {};

export default DashboardNotificationsPage;
