import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';

interface Props {
    label: string;
    value?: string;
    className?: string;
    OnChange: (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => void;
}

const ZTextFieldDropdown: React.FC<Props> = ({ label, value, className, OnChange }) => {
    return (
        <div className={className} css={cssStyles}>
            <FormControl key={label} variant='outlined' className='select'>
                <InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
                <Select
                    fullWidth
                    margin='none'
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    defaultValue='USD'
                    value={value}
                    onChange={OnChange}
                    label={label}>
                    <MenuItem value={'USD'}>USD</MenuItem>
                    <MenuItem value={'AUD'}>AUD</MenuItem>
                    <MenuItem value={'CAD'}>CAD</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

ZTextFieldDropdown.defaultProps = {
    className: 'sss'
};

const cssStyles = css`
    .select {
        width: 100%;
        margin: 0px;
    }
`;

export default ZTextFieldDropdown;
