import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Chip } from '@material-ui/core';

interface Props {
    label: string;
    keeps: boolean;
    available: boolean;
    onClick?: () => void;
}

const ZBaitChip: React.FC<Props> = ({ available, keeps, label, onClick }) => {
    const [className, setClassName] = React.useState('');

    React.useEffect(() => {
        if (!keeps) {
            setClassName('root chip-grey');
        } else {
            if (keeps && available) {
                setClassName('root chip-green');
            } else {
                setClassName('root chip-red');
            }
        }
    }, [available, keeps]);

    return (
        <div css={cssStyles}>
            <Chip label={label} clickable color='primary' onClick={onClick} className={className} />
        </div>
    );
};

ZBaitChip.defaultProps = {};

const cssStyles = css`
    .root {
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .chip-grey {
        margin: 3px 0px;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        color: white;
        padding: 5px 0px;
        background-color: #b2bec3;
    }
    .chip-green {
        margin: 3px 0px;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        color: white;
        padding: 5px 0px;
        background-color: #4dae4d;
    }
    .chip-red {
        margin: 3px 0px;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        color: white;
        padding: 5px 0px;
        background-color: #ed1f32;
    }
`;

export default ZBaitChip;
