const moment = require('moment');
export class Report implements IReport {
    standId: string;
    standName: string;
    title: string;
    description: string;
    timestamp: string;
    photo: string;
    documentId: string;
    timestampMs: number;
    timestampStr: string;

    constructor(x: IReport) {
        this.documentId = x.documentId;
        this.description = x.description;
        this.standId = x.standId;
        this.standName = x.standName;
        this.timestamp = x.timestamp;
        this.timestampMs = x.timestampMs;
        this.photo = x.photo;
        this.title = x.title;
        this.timestampStr = x.timestampStr;
    }

    static fromFirestore(
        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ): Report | null {
        const data = doc.data();

        if (!data) return null;

        const timestampStr = moment(data['timestamp']).format('MMM Do, YYYY');

        return new Report({
            documentId: doc.id,
            standId: data['standId'] ?? '',
            description: data['description'] ?? '',
            photo: data['photo'] ?? '',
            standName: data['standName'] ?? '',
            timestamp: data['timestamp'] ?? '',
            timestampMs: data['timestampMs'] ?? 0,
            title: data['title'] ?? '',
            timestampStr: timestampStr,
        });
    }
}

interface IReport {
    standId: string;
    standName: string;
    title: string;
    description: string;
    timestamp: string;
    timestampStr: string;
    photo: string;
    documentId: string;
    timestampMs: number;
}
