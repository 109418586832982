import axios from 'axios';
import { Reservation, Item } from '../models/reservation';
import { db, firebase } from '../firebase';
import * as DateFns from 'date-fns';
import { NumberParser } from '../app_utils/numberParse';

const baseUrl = 'https://us-central1-baitfinder-94065.cloudfunctions.net/httpsStripe/api/v1';

export class StripeService {
    static acceptOrder = async function (reservation: Reservation, confidence: string) {
        try {
            await db.collection('v2reservations').doc(reservation.documentId).update({
                accepted: true,
                confidence: confidence,
            });

            await firebase.functions().httpsCallable('notifyOrderAccepted')({
                fcm_token: reservation.fcmToken,
                stand_name: reservation.standName,
            });

            await firebase.functions().httpsCallable('emailOrderAccepted')({
                stand_name: reservation.standName,
                user_email: reservation.email,
                order_details: [Item.toJson(reservation.items)],
                order_total: reservation.totalAmount,
                sales_tax: reservation.salesTax,
                reservation_fee: reservation.reservationFee,
                confidence: getConfidence(confidence),
            });

            return true;
        } catch (error) {
            return false;
        }
    };
    static confirmOrder = async function (reservation: Reservation) {
        const pickupDate = DateFns.format(
            DateFns.toDate(Date.parse(reservation.pickUpDateTime)),
            'yyyy-MMM-dd'
        );

        try {
            await db.collection('v2reservations').doc(reservation.documentId).update({
                confirmed: true,
            });

            await firebase.functions().httpsCallable('emailOrderDelivered')({
                fcm_token: reservation.fcmToken,
                stand_name: reservation.standName,
            });

            await firebase.functions().httpsCallable('notifyOrderDelivered')({
                stand_name: reservation.standName,
                user_email: reservation.email,
                order_details: [Item.toJson(reservation.items)],
                order_total: reservation.totalAmount,
                sales_tax: reservation.salesTax,
                reservation_fee: reservation.reservationFee,
                pickup_date: pickupDate,
            });

            return true;
        } catch (error) {
            return false;
        }
    };

    static refundFull = async function (reservation: Reservation, reason: string) {
        const pickupDate = DateFns.format(
            DateFns.toDate(Date.parse(reservation.pickUpDateTime)),
            'yyyy-MMM-dd'
        );

        console.log(reservation.documentId);

        const result = await axios({
            method: 'post',
            url: `${baseUrl}/refundFull`,

            data: {
                chargeId: reservation.token,
            },
        });

        if (result.status === 200) {
            try {
                await db.collection('v2reservations').doc(reservation.documentId).update({
                    refunded: true,
                    rejected: true,
                    rejection_reason: reason,
                });

                await firebase.functions().httpsCallable('notifyOrderRejected')({
                    fcm_token: reservation.fcmToken,
                    stand_name: reservation.standName,
                });

                await firebase.functions().httpsCallable('emailOrderRejected')({
                    stand_name: reservation.standName,
                    user_email: reservation.email,
                    order_id: reservation.documentId,
                    reject_reason: reason,
                    pickup_date: pickupDate,
                    pickup_time: reservation.timeslot.timeslotString,
                });

                return true;
            } catch (error) {
                return false;
            }
        }

        return false;
    };

    static refundLessAmount = async function (
        reservation: Reservation,
        refundAmount: number | string
    ) {
        console.log(reservation.documentId);

        const amount = NumberParser({ number: refundAmount, fixed: 10 }) * 100;

        console.log(amount);

        const result = await axios({
            method: 'post',
            url: `${baseUrl}/refundPartial`,

            data: {
                chargeId: reservation.token,
                amount: amount,
            },
        });

        if (result.status === 200) {
            console.log('########### Refund Amount', result.data.amount / 100);
            try {
                await db
                    .collection('v2reservations')
                    .doc(reservation.documentId)
                    .update({
                        completed: true,
                        refunded: true,
                        refundedAmount: (result.data.amount / 100).toString(),
                        refundCompleted: true,
                        markForRefund: false,
                    });

                await firebase.functions().httpsCallable('emailOrderRefundedAmount')({
                    user_email: reservation.email,
                    stand_name: reservation.standName,
                    order_id: reservation.documentId,

                    refund_amount: (result.data.amount / 100).toString(),
                });

                return true;
            } catch (error) {
                return false;
            }
        }

        return false;
    };

    static refundLessFees = async function (reservation: Reservation, reason: string) {
        console.log(reservation.documentId);

        const amount =
            (NumberParser({ number: reservation.totalAmount, fixed: 10 }) -
                NumberParser({ number: reservation.reservationFee, fixed: 10 })) *
            100;

        console.log(NumberParser({ number: reservation.totalAmount, fixed: 10 }));
        console.log(NumberParser({ number: reservation.reservationFee, fixed: 10 }));
        console.log(amount);

        const result = await axios({
            method: 'post',
            url: `${baseUrl}/refundPartial`,

            data: {
                chargeId: reservation.token,
                amount: amount,
            },
        });

        if (result.status === 200) {
            console.log('########### Refund Amount', result.data.amount / 100);
            try {
                await db
                    .collection('v2reservations')
                    .doc(reservation.documentId)
                    .update({
                        completed: true,
                        refunded: true,
                        refundedAmount: (result.data.amount / 100).toString(),
                        refundCompleted: true,
                        markForRefund: false,
                    });

                await firebase.functions().httpsCallable('emailOrderRefundedLessFees')({
                    user_email: reservation.email,
                    stand_name: reservation.standName,
                    order_id: reservation.documentId,
                    order_total: reservation.totalAmount,
                    refund_amount: (result.data.amount / 100).toString(),
                });

                return true;
            } catch (error) {
                return false;
            }
        }

        return false;
    };

    static markForReview = async function (
        reservation: Reservation | undefined,
        title: string,
        description: string
    ) {
        if (reservation)
            try {
                await db
                    .collection('v2reservations')
                    .doc(reservation.documentId)
                    .update({
                        reviewData: {
                            title: `"FILED BY STAND: " ${title}`,
                            description: description,
                            timestamp: Date.now().toString(),
                            timestampAdded: Date.now(),
                            photo: '',
                        },
                        markForReview: true,
                        reviewCompleted: false,
                    });
                console.log('DONE!!!!');
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
    };

    static completeOrder = async function (reservation: Reservation, noShow: boolean) {
        const result = await axios({
            method: 'post',
            url: `${baseUrl}/captureCharge`,

            data: {
                chargeId: reservation.token,
            },
        });

        if (result.status === 200) {
            try {
                await db.collection('v2reservations').doc(reservation.documentId).update({
                    completed: true,
                    charge_id: result.data.id,
                    noShow: noShow,
                });

                await firebase.functions().httpsCallable('notifyOrderDelivered')({
                    stand_name: reservation.standName,
                    fcm_token: reservation.fcmToken,
                    stand_id: reservation.standId,
                });

                await firebase.functions().httpsCallable('emailOrderDelivered')({
                    stand_name: reservation.standName,
                    user_email: reservation.email,
                    order_details: [Item.toJson(reservation.items)],
                    order_total: reservation.totalAmount,
                    sales_tax: reservation.salesTax,
                    reservation_fee: reservation.reservationFee,
                });

                return true;
            } catch (error) {
                return false;
            }
        }
    };
}

function getConfidence(confidence: string) {
    switch (confidence) {
        case '1':
            return '<50%';
        case '2':
            return '50-75%';
        case '3':
            return '75-95%';
        case '4':
            return '95+%';
        default:
            return '';
    }
}
