import * as React from 'react';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

interface Props {
    label: string;
    value: boolean;
    name?: string;
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

const ZSwitch: React.FC<Props> = ({ label, value, onChange, name }) => {
    return (
        <div>
            <Grid container alignContent='center' alignItems='center' justify='space-between'>
                <p> {label}</p>
                <Switch
                    name={name}
                    onChange={onChange}
                    checked={value}
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Grid>
        </div>
    );
};

ZSwitch.defaultProps = {};

export default ZSwitch;
