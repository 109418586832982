import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Reservation } from '../models/reservation';
import ZDialogForm from './ZDialogForm';
import { Grid, Divider, makeStyles, Theme, Typography, TextField } from '@material-ui/core';

import { StripeService } from '../models_services/StripeService';
import ZTextField from './ZTextField';
import { useForm } from 'react-hook-form';
import { NumberParser } from '../app_utils/numberParse';
export enum ResevationProgress {
    accept,
    confirm,
    deliver,
    history,
}

export enum ButtonTypes {
    default,
    accept,
    reject,
    raiseIssue,
    refund,
    refundPartial,
}

type FormData = {
    title: string;
    description: string;
    refundAmount: number;
    refundLessFeesReason: string;
};

interface Props {
    title: string;
    open: boolean;
    message: any;
    reservation: Reservation | undefined;
    reservationProgress: ResevationProgress;
    openDialogCallback: any;
}

const ZDialogFormReservation: React.FC<Props> = ({
    title,
    open,
    message,
    reservation,
    reservationProgress,
    openDialogCallback,
}) => {
    const [buttonTypes, setButtonTypes] = React.useState<ButtonTypes>(ButtonTypes.default);
    const [rejectReason, setRejectReason] = React.useState<string>('');
    const [errorRefund, setErrorRefund] = React.useState<string>('');
    const { handleSubmit, errors, control } = useForm<FormData>({});
    const classes = useStyles();

    const onSubmitRefundAmount = handleSubmit((data: FormData) => {
        console.log('from', data);
        const refundAmount = NumberParser({ number: data.refundAmount, fixed: 2 });
        const reservationAmount = NumberParser({ number: reservation!.totalAmount, fixed: 2 });

        console.log(refundAmount);
        console.log(reservationAmount);

        if (refundAmount > reservationAmount) {
            setErrorRefund(`Refund amount should be lower than $${reservation!.totalAmount}`);
        } else {
            setErrorRefund(``);
            if (reservation) {
                StripeService.refundLessAmount(reservation, refundAmount);
                openDialogCallback(false);
            }
        }
    });

    const onSubmitRefundLessFees = handleSubmit((data: FormData) => {
        console.log('from', data);
        if (reservation) {
            StripeService.refundLessFees(reservation, data.refundLessFeesReason);
            openDialogCallback(false);
        }
    });

    const onSubmitRaiseIssue = handleSubmit((data: FormData) => {
        console.log('from', data);
        StripeService.markForReview(reservation, data.title, data.description);
        openDialogCallback(false);
    });
    return (
        <div>
            <ZDialogForm
                open={open}
                title=''
                message={
                    <>
                        {buttonTypes === ButtonTypes.default && (
                            <Grid className={classes.closeDialog} container justify='flex-end'>
                                <Button
                                    variant='outlined'
                                    color='secondary'
                                    onClick={() => {
                                        openDialogCallback(false);
                                    }}>
                                    CLose
                                </Button>
                            </Grid>
                        )}

                        {reservation && (
                            <div className={classes.form}>
                                {buttonTypes === ButtonTypes.default && (
                                    <>
                                        <Grid container>
                                            <div className={classes.username}>
                                                {reservation.username}
                                            </div>
                                            <div className={classes.email}>{reservation.email}</div>
                                        </Grid>
                                        <Divider className={classes.divider} />
                                        <Grid container>
                                            <div className={classes.orderIdTitle}>Order Id</div>
                                            <div className={classes.orderId}>
                                                #{reservation.documentId}
                                            </div>
                                        </Grid>
                                        <Divider className={classes.divider} />

                                        {/* {reservationProgress === ResevationProgress.deliver && (
                                    <Grid container justify='space-between'>
                                        <Grid item>Passcode</Grid>
                                        <Grid item>
                                            <div>{reservation.password}</div>
                                        </Grid>
                                    </Grid>
                                )} */}

                                        <Grid container>
                                            <div className={classes.pickupTime}>pickup time: </div>
                                            <div className={classes.pickupTimeValue}>
                                                {reservation.pickUpDateTimeStr}
                                                {'   '}
                                                {reservation.timeslot.timeslotString}
                                            </div>
                                        </Grid>
                                        <Divider className={classes.divider} />
                                        <div className='baits'>Baits</div>
                                        <div>
                                            {reservation.items.map((x, index) => (
                                                <div key={index}>
                                                    <Grid
                                                        container
                                                        justify='space-between'
                                                        alignItems='center'>
                                                        <Grid item>
                                                            <div>
                                                                {x.name} {x.baitType}
                                                            </div>
                                                            <div>
                                                                {x.quantity} @ ${x.pricing.currency}{' '}
                                                                {x.pricing.price} {x.pricing.unit}
                                                            </div>
                                                        </Grid>
                                                        <Grid>${x.quantity * x.pricing.price}</Grid>
                                                    </Grid>
                                                    <Divider className={classes.divider} />
                                                </div>
                                            ))}
                                        </div>

                                        <Grid container justify='space-between'>
                                            <Grid item>Sales Tax</Grid>
                                            <Grid item>
                                                <div>${reservation.salesTax}</div>
                                            </Grid>
                                        </Grid>

                                        <Divider className={classes.divider} />

                                        <Grid container justify='space-between'>
                                            <Grid item>Reservation Fee</Grid>
                                            <Grid item>${reservation.reservationFee}</Grid>
                                        </Grid>

                                        <Divider className={classes.divider} />

                                        <Grid container justify='space-between'>
                                            <Grid item>Total Amount</Grid>
                                            <Grid item>${reservation.totalAmount}</Grid>
                                        </Grid>

                                        <Divider className={classes.divider} />
                                    </>
                                )}
                                {reservationProgress === ResevationProgress.accept &&
                                    buttonTypes === ButtonTypes.default && (
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.accept);
                                                }}
                                                className={classes.btnAccept}
                                                variant='contained'
                                                color='primary'
                                                fullWidth>
                                                Accept Order
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.reject);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Reject Order
                                            </Button>
                                        </div>
                                    )}
                                {reservationProgress === ResevationProgress.confirm &&
                                    buttonTypes === ButtonTypes.default && (
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    StripeService.confirmOrder(reservation);
                                                    setButtonTypes(ButtonTypes.default);
                                                    setRejectReason('');
                                                    openDialogCallback(false);
                                                }}
                                                className={classes.btnAccept}
                                                variant='contained'
                                                color='primary'
                                                fullWidth>
                                                Confirm Order
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.reject);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Reject Order
                                            </Button>
                                        </div>
                                    )}
                                {reservationProgress === ResevationProgress.deliver &&
                                    buttonTypes === ButtonTypes.default && (
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.reject);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Refund Order
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    StripeService.completeOrder(reservation, false);
                                                    setButtonTypes(ButtonTypes.default);
                                                    setRejectReason('');
                                                    openDialogCallback(false);
                                                }}
                                                className={classes.btnAccept}
                                                variant='contained'
                                                color='primary'
                                                fullWidth>
                                                Complete Order
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    StripeService.completeOrder(reservation, true);
                                                    setButtonTypes(ButtonTypes.default);
                                                    setRejectReason('');
                                                    openDialogCallback(false);
                                                }}
                                                className={classes.btnAccept}
                                                variant='contained'
                                                color='primary'
                                                fullWidth>
                                                Complete Order (No-show)
                                            </Button>
                                        </div>
                                    )}
                                {reservationProgress === ResevationProgress.history &&
                                    buttonTypes === ButtonTypes.default &&
                                    !reservation.rejected && (
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.refund);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Refund Order(Amount)
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.refundPartial);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Refund Order(Patial/Less Fees)
                                            </Button>
                                            {!reservation.markForReview && (
                                                <Button
                                                    onClick={() => {
                                                        setButtonTypes(ButtonTypes.raiseIssue);
                                                    }}
                                                    className={classes.btnReject}
                                                    variant='contained'
                                                    color='secondary'
                                                    fullWidth>
                                                    Raise issue
                                                </Button>
                                            )}
                                            {reservation.markForReview && (
                                                <Typography className={classes.rejectedOrder}>
                                                    This order is under review
                                                </Typography>
                                            )}
                                        </div>
                                    )}
                                {reservationProgress === ResevationProgress.history &&
                                    buttonTypes === ButtonTypes.default &&
                                    reservation.rejected && (
                                        <Grid
                                            container
                                            direction='column'
                                            alignItems='center'
                                            className={classes.rejectedOrder}>
                                            <Typography>This order was rejected</Typography>
                                            <Divider className={classes.divider} />
                                            <Typography>{reservation.rejection_reason}</Typography>
                                        </Grid>
                                    )}

                                {buttonTypes === ButtonTypes.accept && (
                                    <Grid container alignItems='center'>
                                        <Typography className={classes.buttonPageTitle}>
                                            How sure are you that you will be able to fulfill this
                                            reservation
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Button
                                                    onClick={() => {
                                                        StripeService.acceptOrder(reservation, '4');
                                                        setButtonTypes(ButtonTypes.default);
                                                        setRejectReason('');
                                                        openDialogCallback(false);
                                                    }}
                                                    fullWidth
                                                    variant='outlined'
                                                    color='primary'
                                                    className='accept95up'>
                                                    95%+
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    onClick={() => {
                                                        StripeService.acceptOrder(reservation, '3');
                                                        setButtonTypes(ButtonTypes.default);
                                                        setRejectReason('');
                                                        openDialogCallback(false);
                                                    }}
                                                    fullWidth
                                                    variant='outlined'
                                                    color='primary'
                                                    className='accept75up'>
                                                    75-95%
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    onClick={() => {
                                                        StripeService.acceptOrder(reservation, '2');
                                                        setButtonTypes(ButtonTypes.default);
                                                        setRejectReason('');
                                                        openDialogCallback(false);
                                                    }}
                                                    fullWidth
                                                    variant='outlined'
                                                    color='primary'
                                                    className='accept50up'>
                                                    50-75%
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    onClick={() => {
                                                        StripeService.acceptOrder(reservation, '1');
                                                        setButtonTypes(ButtonTypes.default);
                                                        setRejectReason('');
                                                        openDialogCallback(false);
                                                    }}
                                                    fullWidth
                                                    variant='outlined'
                                                    color='primary'
                                                    className='accept0up'>
                                                    50%
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Button
                                            onClick={() => {
                                                setButtonTypes(ButtonTypes.default);
                                            }}
                                            className={classes.btnReject}
                                            variant='contained'
                                            color='secondary'
                                            fullWidth>
                                            Cancel
                                        </Button>
                                    </Grid>
                                )}
                                {buttonTypes === ButtonTypes.reject && (
                                    <Grid container alignItems='center'>
                                        <Typography className={classes.buttonPageTitle}>
                                            Enter a rejection reason
                                        </Typography>

                                        <TextField
                                            onChange={(event) => {
                                                setRejectReason(event.target.value);
                                            }}
                                            fullWidth
                                            margin='dense'
                                            variant='outlined'
                                            label='Reason'
                                        />

                                        <Button
                                            onClick={() => {
                                                StripeService.refundFull(reservation, rejectReason);
                                                setButtonTypes(ButtonTypes.default);
                                                setRejectReason('');
                                                openDialogCallback(false);
                                            }}
                                            className={classes.btnAccept}
                                            variant='contained'
                                            color='primary'
                                            fullWidth>
                                            Submit
                                        </Button>

                                        <Button
                                            onClick={() => {
                                                setButtonTypes(ButtonTypes.default);
                                            }}
                                            className={classes.btnReject}
                                            variant='contained'
                                            color='secondary'
                                            fullWidth>
                                            Cancel
                                        </Button>
                                    </Grid>
                                )}

                                {buttonTypes === ButtonTypes.raiseIssue && (
                                    <Grid container alignItems='center'>
                                        <form
                                            onSubmit={onSubmitRaiseIssue}
                                            className={classes.form}>
                                            <ZTextField
                                                name='title'
                                                control={control}
                                                error={errors.title ? true : false}
                                                label='Title'
                                                defaultValue={''}></ZTextField>
                                            <ZTextField
                                                name='description'
                                                control={control}
                                                error={errors.description ? true : false}
                                                label='Description'
                                                defaultValue={''}></ZTextField>
                                            <Button
                                                onClick={onSubmitRaiseIssue}
                                                className={classes.btnAccept}
                                                variant='contained'
                                                color='primary'
                                                fullWidth>
                                                Submit
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.default);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Cancel
                                            </Button>
                                        </form>
                                    </Grid>
                                )}
                                {buttonTypes === ButtonTypes.refundPartial && (
                                    <Grid container alignItems='center'>
                                        <form
                                            onSubmit={onSubmitRefundLessFees}
                                            className={classes.form}>
                                            <ZTextField
                                                name='refundLessFeesReason'
                                                control={control}
                                                error={errors.refundLessFeesReason ? true : false}
                                                label='Refund Reason'
                                                defaultValue={''}></ZTextField>
                                            <Button
                                                onClick={onSubmitRefundLessFees}
                                                className={classes.btnAccept}
                                                variant='contained'
                                                color='primary'
                                                fullWidth>
                                                Submit
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.default);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Cancel
                                            </Button>
                                        </form>
                                    </Grid>
                                )}

                                {buttonTypes === ButtonTypes.refund && (
                                    <Grid container alignItems='center'>
                                        <Typography>
                                            Current Order Total ${reservation.totalAmount}
                                        </Typography>
                                        <form
                                            onSubmit={onSubmitRefundAmount}
                                            className={classes.form}>
                                            <ZTextField
                                                name='refundAmount'
                                                type='number'
                                                control={control}
                                                error={errors.refundAmount ? true : false}
                                                label='Refund Amount'
                                                defaultValue={''}></ZTextField>
                                            <Button
                                                onClick={onSubmitRefundAmount}
                                                className={classes.btnAccept}
                                                variant='contained'
                                                color='primary'
                                                fullWidth>
                                                Submit
                                            </Button>

                                            <Button
                                                onClick={() => {
                                                    setButtonTypes(ButtonTypes.default);
                                                }}
                                                className={classes.btnReject}
                                                variant='contained'
                                                color='secondary'
                                                fullWidth>
                                                Cancel
                                            </Button>
                                            <Typography className={classes.rejectedOrder}>
                                                {errorRefund}
                                            </Typography>
                                        </form>
                                    </Grid>
                                )}
                            </div>
                        )}
                    </>
                }></ZDialogForm>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        minWidth: '500px',
        minHeight: '500px',
        maxHeight: '700px',
        overflow: 'auto',
    },
    username: {
        fontSize: '17px',
        color: '#1b3a57',
        fontWeight: 600,
    },

    email: {
        fontSize: '17px',
        color: '#1b3a57',
        fontWeight: 600,

        marginLeft: '10px',
    },
    orderIdTitle: {
        fontSize: '17px',
        color: '#1b3a57',
        fontWeight: 600,
    },
    orderId: {
        fontSize: '17px',
        color: '#1b3a57',
        fontWeight: 600,

        marginLeft: '10px',
    },

    pickupTime: {
        fontSize: '17px',
        color: '#1b3a57',
        fontWeight: 600,

        marginRight: '10px',
    },
    pickupTimeValue: {
        fontSize: '17px',
        color: '#fd79a8',
        fontWeight: 600,
    },

    divider: {
        margin: '10px 0px',
    },

    closeDialog: {
        marginBottom: '10px',
    },
    btnAccept: {
        margin: '10px 0px',
    },
    btnReject: {
        margin: '10px 0px',
    },
    rejectedOrder: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '20px',
        color: '#fd79a8',
        fontWeight: 700,
        margin: '20px 0px',
    },

    buttonPageTitle: {
        textAlign: 'center',
        fontSize: '18px',
        color: '#1b3a57',
        fontWeight: 600,
        margin: '15px',
    },
}));

export default ZDialogFormReservation;
